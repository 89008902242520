import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		active: sessionStorage.getItem('active') || '/',
		unitList:[],
		userInfo: {},
		tabList: [{
			permissionkey: 'home',
			url: 'home',
			name: '首页',
			logo: 'iconfont icon-shouye'
		}, {
			permissionkey: 'addGoods',
			url: 'addGoods',
			name: '添加商品',
			logo: 'iconfont icon-shouye'
		}, {
			permissionkey: 'print_design2',
			url: 'print_design2',
			name: '编辑模板',
			logo: 'iconfont icon-shouye'
		},],
		goodsInfo: {
			id: 0,
			code: "", 
			name: "", 
			zjm: "",  
			cid: 0, 
			spec: "", 
			place: "", 
			unit1_id: 0, 
			unit1_code: "", 
			unit2_id: 0, 
			unit2_code: "", 
			unit2_unit1: 0, 
			status: 1,
			detail: {},
			price: [],
		},
	},
	getters: {},
	mutations: {
		setUserInfo(state, newval) {
			state.userInfo = newval
		},
		changeActive (state, active) {
			state.active = active
			sessionStorage.setItem('active', active)
		  },
		changeunitList(state,data){
			state.unitList = data
		},
		
		setBasic(state, data){
			state.goodsInfo = data
		},
	},
	actions: {
		getUserInfo({
			commit
		}) {
			Vue.prototype.$http.get("admin/users/info").then(res => {
				commit("setUserInfo", res.data.data)
			})
		},

	},
	modules: {}
})
