<template>
  <div class="common-tab" style="height: 100%;">
    <div class="tab-menu">
      <div>
        <!-- <el-tag :key="tag.url" v-for="tag in tags">
					{{ tag.name }}
					
				</el-tag> -->
        <el-tabs
          v-if="clickMenu"
          v-model="editableTabsValue"
          type="border-card"
          @tab-remove="removeTab"
          @tab-click="changeTab"
        >
          <el-tab-pane
            :closable="item.content === 'HomeView'?false:true"
            v-for="item in editableTabs"
            :key="item.name"
            :name="item.name"
          >
          <span slot="label"><i v-if="item.title === '动态首页'" style="font-size: 20px;" class="el-icon-s-home"></i> {{ item.title=== '动态首页'?'':item.title }}</span>
            <!-- <component
              :is="item.content"
              :id="item.id"
              :flag="item.flag"
            ></component> -->
            <router-view v-if="item.name===editableTabsValue"></router-view>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {

  data() {
    return {
      clickMenu: false,
      editableTabsValue: "1",
      editableTabs: [],
      tabIndex: 0,
    };
  },
  created() {
    this.active = sessionStorage.getItem('active') || '/'
    this.$store.commit("changeActive", this.active);
    this.editableTabsValue = sessionStorage.getItem("editableTabsValue") || "1";
    this.tabIndex = sessionStorage.getItem("tabIndex") || 1;
    this.editableTabs = JSON.parse(sessionStorage.getItem("editableTabs"));
    if (this.editableTabs.length) {
      this.clickMenu = true;
    }
  },
  mounted() {
    this.$EventBus.$on("aMenu", (e) => {
      // A发送来的消息
      this.clickMenu = true;
      let flag;
      // 添加标签卡
      this.editableTabs.forEach((itm) => {
        if (itm.id == e.id) {
          this.editableTabsValue = itm.name;
          flag = true;
        }
      });
      if (!flag) {
        let newTabName = ++this.tabIndex + "";
        this.editableTabs.push({
          title: e.title,
          name: newTabName,
          content: e.path === "/" ? "HomeView" : e.path,
          id: e.id,
        });
        this.editableTabsValue = newTabName;
        sessionStorage.setItem("editableTabsValue", this.editableTabsValue);
        sessionStorage.setItem(
          "editableTabs",
          JSON.stringify(this.editableTabs)
        );
        sessionStorage.setItem("tabIndex", this.tabIndex);
      }
    });
    

    this.$EventBus.$on('close',(msg)=>{
      let temp
      this.editableTabs.forEach((item)=>{
        if(item.content === msg){
          temp = item.name
        }
      })
      this.removeTab(temp)
    })
  
  },
  computed: {
    ...mapState({
      tags: (state) => state.tabList,
    }),
  },
  methods: {
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              this.active = nextTab.content;
              if (nextTab.content === "HomeView") {
                this.$router.push({ path: "/" });
                this.$store.commit("changeActive", "/");
              } else if(nextTab.content === 'addGoods' || nextTab.content === 'editGoods'){
                this.$router.push({ path: "/" + this.active });
                this.$store.commit('changeActive','goods')
              } else {
                this.$router.push({ path: "/" + this.active });
                this.$store.commit("changeActive", this.active);
              }
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      if (!this.editableTabs.length) {
        this.clickMenu = false;
        this.$store.commit("changeActive", "");
      }
      sessionStorage.setItem("editableTabsValue", this.editableTabsValue);
      sessionStorage.setItem("editableTabs", JSON.stringify(this.editableTabs));
      sessionStorage.setItem("tabIndex", this.tabIndex);
    },
    changeTab(item) {
      this.editableTabs.forEach((itm) => {
        if (itm.name == item.name) {
          this.active = itm.content;
          if (itm.content === "HomeView") {
            this.$router.push({ path: "/" });
            this.$store.commit("changeActive", "/");
          } else if(itm.content === 'addGoods' || itm.content === 'editGoods') {
            this.$router.push({ path: "/" + this.active });
            this.$store.commit("changeActive", "goods");
          }else{
            this.$router.push({ path: "/" + this.active });
            this.$store.commit("changeActive", this.active);
          }
        }
      });
      sessionStorage.setItem("editableTabsValue", this.editableTabsValue);
    },
  },
};
</script>

<style lang="less" scoped>

:deep(.el-tabs__item){
  height: 50px !important;
  line-height: 50px !important;
}
.common-tab {
  display: flex;
  // height: auto;
  // overflow-y: auto;
}

.tab-menu {
  width: 100%;
}

/deep/ .el-tabs__content {
	padding: 0px!important;
	height: calc(100vh - 50px);
	overflow-y: auto;
}
</style>
