<template>
	<div style="padding: 15px; height: 100%">
		<el-dialog :title="ruleForm.id == 0 ? '添加分类' : '修改分类'" :visible.sync="dialogVisible" width="30%"
			:before-close="handleClose">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="名称" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="父分类">
					<el-cascader v-model="value" :options="cateList" :props="{
              expandTrigger: 'hover',
              value: 'id',
              label: 'name',
              children: 'Children',
              checkStrictly: true,
            }" @change="handleChange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="排序">
					<el-input-number v-model="ruleForm.sort" controls-position="right" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="显示">
					<el-radio-group v-model="ruleForm.show">
						<el-radio :label="1">显示</el-radio>
						<el-radio :label="2">不显示</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="创建订单" :visible.sync="dialogVisible2" width="30%" :before-close="handleClose2">
			<el-form label-position="top" :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="仓库" prop="warehouse_id">
					<el-select v-model="ruleForm2.warehouse_id">
						<el-option v-for="item in warehouse" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户" prop="mid">
					<el-select v-model="ruleForm2.mid">
						<el-option v-for="item in members" :key="item.id" :label="item.real_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-table :data="multipleSelection">
					<el-table-column prop="name" label="商品名称"></el-table-column>
					<el-table-column label="选择单位">
						<template slot-scope="scope">
							<el-select v-model="multipleSelection[scope.$index].unit" placeholder="请选择">
							    <el-option
							      :label="multipleSelection[scope.$index].unit1_name"
							      :value="multipleSelection[scope.$index].unit1_id + ',1'">
							    </el-option>
								<el-option
								  :label="multipleSelection[scope.$index].unit2_name"
								  :value="multipleSelection[scope.$index].unit2_id + ',2'">
								</el-option>
						  </el-select>
						</template>
					</el-table-column>
					<el-table-column label="入库数量">
						<template slot-scope="scope">
							<el-input-number v-model="multipleSelection[scope.$index].goods_num" :min="1" :max="500" label="描述文字">
							</el-input-number>
						</template>
					</el-table-column>

				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="submitForm2('ruleForm2')">提交</el-button>
					<el-button @click="resetForm2('ruleForm2')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-card shadow="always" style="margin-bottom: 20px">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="toPost">添加商品</el-button>
<!-- 					<el-button type="primary" @click="print">打印表单</el-button>
					<el-button type="primary" @click="preview">预览表单</el-button> -->
				</el-col>
		<!-- 		<el-col :span="2">
					<el-button type="primary" @click="openForm">入库</el-button>
				</el-col> -->
			</el-row>
		</el-card>
		<div class="a1">
			<div class="d1">
				<div class="b1">
					<span class="s1">商品分类</span>
					<dir class="b2">
						<span class="s2" @click="addRow">添加</span>
						<span class="s2" @click="editRow">编辑</span>
					</dir>
				</div>
				<el-tree :data="cateList" node-key="id" default-expand-all :expand-on-click-node="false"
					:props="{ label: 'name', children: 'Children' }" @node-click="handleNodeClick">
				</el-tree>
			</div>
			<div class="a2">
				<el-table :data="tableData" border ref="multipleTable" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="name" label="商品名称"></el-table-column>
					<el-table-column prop="zjm" label="助记码"></el-table-column>
					<el-table-column prop="brand_name" label="品牌"></el-table-column>
					<el-table-column prop="spec" label="规格"></el-table-column>
					<el-table-column prop="unit1_code" label="小单位条码"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button @click="editGoods(scope.row)" type="text" size="small">编辑</el-button>
							<!-- <el-button @click="delRow(scope.row)" type="text" size="small">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				multipleTable: [],
				multipleSelection: [],
				value: [""],
				ruleForm: {
					id: 0,
					name: "",
					fid: 0,
					sort: 1,
					show: 1,
				},
				rules: {
					name: [{
						required: true,
						message: "请输入品牌名称",
						trigger: "blur",
					}, ],
				},
				ruleForm2: {
					warehouse_id: null,
					mid: null,
				},
				rules2: {
					warehouse_id: [{
						required: true,
						message: '请选择仓库',
						trigger: 'blur'
					}],
					mid: [{
						required: true,
						message: '请选择用户',
						trigger: 'blur'
					}],
				},
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10,
				},
				tableData: [],
				cateList: [],
				dialogVisible: false,
				dialogVisible2: false,
				checkCate: {
					id: 0,
					name: "",
					fid: 0,
					sort: 1,
					show: 1,
				},
				addGood: {
					title: '创建商品',
					path: 'addGoods',
					id: 10001
				},
				editGood: {
					title: '编辑商品',
					path: 'editGoods',
					id: null,
				},
				warehouse: [],
				members: []
			};
		},

		mounted() {
			this.$http.get("admin/warehouse/list", {
				params: this.pageInfo
			}).then(res => {
				this.warehouse = res.data.data.data
			})
			this.$http.get("admin/member/list", {
				params: this.pageInfo
			}).then(res => {
				this.members = res.data.data.data
			})
			this.getCate();
			this.getData();
		},

		methods: {
			print() {
				let temp = sessionStorage.getItem("demo")
				console.log(JSON.parse(temp))
				let data = [{"companyName": "你好公司"}]
				this.$lodop.print(JSON.parse(temp), data);
			},
			
			preview() {
				let temp = sessionStorage.getItem("demo")
				console.log(JSON.parse(temp))
				let data = [{"companyName": "你好公司"}]
				// 预览打印内容
				this.$lodop.preview(JSON.parse(temp), data);
			},
			
			handleChange2(index, row) {
				this.multipleSelection[index] = row
			},
			
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			cSelection() {
				this.$refs.multipleTable.clearSelection();
			},
			openForm() {
				if (this.multipleSelection.length <= 0) {
					this.$message({
						message: "请选择商品",
						type: "warning",
					});
					return
				}
				// this.multipleSelection.forEach((item) => {
				// 	item.goods_num = 1
				// })
				console.log(this.multipleSelection)
				this.dialogVisible2 = true
			},

			toPost() {
				this.$router.push({
					path: "/addGoods"
				});
				this.$EventBus.$emit("aMenu", this.addGood);
				// this.$store.commit("changeActive", "addGoods");
			},

			editGoods(goods) {
				this.$router.push({
					path: "/editGoods",
					query: {
						id: goods.id
					}
				});
				this.editGoods.id = goods.id
				this.$EventBus.$emit("aMenu", this.editGood);
			},


			handleClose2() {
				this.dialogVisible2 = false;
				this.multipleSelection = []
				this.cSelection()
			},

			handleClose() {
				this.dialogVisible = false;
				this.checkCate.id = 0;
				this.ruleForm = {
					id: 0,
					name: "",
					fid: 0,
					sort: 1,
					show: 1,
				};
				this.value = [];
			},
			handleSizeChange(val) {
				this.pageInfo.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val;
				this.getData();
			},

			handleChange(value) {
				this.value = value;
				console.log(this.value);
				this.ruleForm.fid = value[value.length - 1];
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			
			resetForm2(formName) {
				// this.multipleSelection = []
				this.$refs[formName].resetFields();
			},

			getData() {
				this.$http
					.get("admin/goods/list", {
						params: this.pageInfo,
					})
					.then((res) => {
						res.data.data.data.forEach(item => {
							item.goods_num = 1
							item.unit = null
						})
						this.tableData = res.data.data.data;
						this.total = res.data.data.count;
					});
			},

			getCate() {
				this.$http.get("admin/cate/list").then((res) => {
					this.cateList = res.data.data;
				});
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.ruleForm.id == 0) {
							this.$http.post("admin/cate/create", this.ruleForm).then((res) => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm");
									this.getCate();
									this.$message({
										message: "添加成功",
										type: "success",
									});
									this.dialogVisible = false;
								}
							});
						} else {
							console.log(this.ruleForm)
							this.$http.post("admin/cate/edit", this.ruleForm).then((res) => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm");
									this.getCate();
									this.$message({
										message: "修改成功",
										type: "success",
									});
									this.dialogVisible = false;
								}
							});
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			
			submitForm2(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let detail = []
						
						this.multipleSelection.forEach(item => {
							let units = item.unit.split(",")
							detail.push({
								"goods_id": item.id, 
								"unit_id": parseInt(units[0]), 
								"unit_type": parseInt(units[1]), 
								"goods_num": item.goods_num
							})
						})
						this.ruleForm2.detail = detail
						this.$http.post("admin/order/inbound", this.ruleForm2).then((res) => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm2");
								this.$message({
									message: "入库成功",
									type: "success",
								});
								this.dialogVisible2 = false;
							}
						});
					} else {
						this.$message({
							message: "入库失败",
							type: "error",
						});
						return false;
					}
				});
			},

			handleNodeClick(data) {
				this.checkCate.id = data.id;
				this.checkCate.name = data.name;
				this.checkCate.fid = data.fid;
				this.checkCate.show = data.show;
				this.checkCate.sort = 1;
			},

			addRow() {
				this.dialogVisible = true;
				this.ruleForm = {
					id: 0,
					name: "",
					fid: 0,
					sort: 1,
					show: 1,
				};
			},

			editRow() {
				if (this.checkCate.id == 0) {
					this.$message({
						message: "请选择分类",
						type: "error",
					});
					return;
				}
				console.log(this.checkCate)
				this.ruleForm = this.checkCate;
				this.dialogVisible = true;
			},
		},
	};
</script>

<style>
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.a1 {
		display: flex;
		flex-direction: row;
		width: 100vw;
		height: 100%;
	}

	.a2 {
		margin-left: 10px;
		width: calc(100vw - 430px);
		height: 100vh;
		border: 1px solid #f1f1f6;
	}

	.b1 {
		height: 32px;
		background: #f3f2f7;
		border: 1px solid #d8dae2;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px;
	}

	.b2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.s1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1a1c2a;
	}

	.s2 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1755c0;
		margin-left: 5px;
		cursor: pointer;
	}

	.d1 {
		height: 100vh;
		width: 180px;
		/* margin-top: 15px; */
		border: 1px solid #f1f1f6;
	}

	.el-table {
		margin-top: 0 !important;
	}
</style>
