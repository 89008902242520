<template>
	<div>
		<kr-print-designer v-if="isShow" :temp-value="value" :widget-options="widgets" :lodop-license="licenseInfo"
			@save="handleSave" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				value: {
				},
				isShow: false,
				widgets: [],
				licenseInfo: {},
				cateList: [],
				pageInfo: {
					page: 1,
					limit: 10,
				},
				tableData: [],
				id: 0,
			};
		},

		async created() {
			this.id = this.$route.query.id
			console.log(this.$route.query.template)
			this.value = JSON.parse(this.$route.query.template)
			
			const res = await this.$http.get("/admin/component/list", {
				params: {
					page: 1,
					limit: 100,
					temp_id: this.id
				}
			})

			this.widgets = res.data.data.data;
			this.isShow = true
		},

		mounted() {},

		methods: {
			addE() {
				this.isShow = false
				this.widgets.push({
					type: "braid-txt",
					isEdit: true,
					title: "自定义文本",
					value: "自定义文本",
					defaultValue: "自定义文本"
				})
				setTimeout(() => {
					this.isShow = true
				}, 10)
			},

			print() {
				let temp = sessionStorage.getItem("demo")
				console.log(JSON.parse(temp))
				let data = [{
					"companyName": "你好公司"
				}]
				this.$lodop.print(JSON.parse(temp), data);
			},

			preview() {
				let temp = sessionStorage.getItem("demo")
				console.log(JSON.parse(temp))
				let data = [{
					"companyName": "你好公司"
				}]
				// 预览打印内容
				this.$lodop.preview(JSON.parse(temp), data);
			},

			handleSave(res) {
				let that = this
				this.$http.post("/admin/report/templateedit", {
					id: that.id,
					name: res.title,
					template: JSON.stringify(res)
				}).then(info => {
					this.$message({
						message: '编辑成功',
						type: 'success'
					});
					// 关闭创建商品的窗口
					this.$EventBus.$emit('close','print_design2')
				})
			},

			getData() {
				this.$http.get("/admin/report/templatelist", {
					params: this.pageInfo,
				}).then((res) => {
					this.tableData = res.data.data.data;
					this.total = res.data.data.count;
				});
			},
		},
	};
</script>

<style>
	.a1 {
		display: flex;
		flex-direction: row;
		width: 100vw;
		height: 100%;
	}

	.a2 {
		margin-left: 10px;
		width: calc(100vw - 430px);
		height: 100vh;
		border: 1px solid #f1f1f6;
	}

	.b1 {
		height: 32px;
		background: #f3f2f7;
		border: 1px solid #d8dae2;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px;
	}

	.b2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.s1 {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1a1c2a;
	}

	.d1 {
		height: 100vh;
		width: 180px;
		border: 1px solid #f1f1f6;
	}
</style>
