<template>
	<div class="layout">
		<!-- 菜单 -->
		<div class="menu">
			<Menu :isCollapse="isCollapse" @changeShow="changeShow"></Menu>
		</div>
		<!-- 主题内容 -->
		<div class="content">
			<Content :isCollapse="isCollapse"></Content>
		</div>
	</div>
</template>

<script>
	import Menu from "./menu/IndexView.vue"
	import Content from "./content/IndexView.vue"
	import {mapActions} from 'vuex'
	export default {
		components: {
			Menu,
			Content
		},
		mounted() {
			this.$store.dispatch("getUserInfo")
		},
		
		data() {
			return {
				isCollapse: false
			}
		},
		methods: {
			changeShow() {
				this.isCollapse = !this.isCollapse
			}
		}
	}
</script>

<style lang="less" scoped>
	.layout {
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden;
		.menu {
			background: #191a23;
		}

		.content {
			width: 100%;
	
			display: flex;
			flex-direction: column;
			// overflow-y: scroll;
			// height: 100vh;
			
		}
	}
</style>
