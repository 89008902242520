import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/IndexView.vue'
import Login from '@/views/login/LoginView.vue'
import Home from '@/views/home/HomeView.vue'
import SysTem from '@/views/system/SysTem.vue'
import Brand from '@/views/content/BrandView.vue'
import Unit from '@/views/content/UnitView.vue'
import Warehouse from '@/views/content/WarehouseView.vue'
import Area from '@/views/content/AreaView.vue'
import Client from '@/views/content/ClientView.vue'
import Goods from '@/views/content/GoodsView.vue'
import AddGoods from '@/views/content/AddGoods.vue'
import EditGoods from "@/views/content/EditGoods.vue"
import InOrder from "@/views/content/InOrder.vue"
import OutOrder from "@/views/content/OutOrder.vue"
import SaleOrder from "@/views/content/SaleOrder.vue"
import ChangeOrder from "@/views/content/ChangeOrder.vue"
import Members from "@/views/content/Members.vue"
import QueryStock from "@/views/content/QueryStock.vue"
import Drivers from "@/views/content/Drivers.vue"
import CarOrder from "@/views/content/CarOrder.vue"
import PrintDesign from "@/views/content/PrintDesign.vue" 
import PrintDesign2 from "@/views/content/PrintDesign2.vue" 
import QuerySaleStock from "@/views/content/QuerySaleStock.vue" 
import SaleBackOrder from "@/views/content/SaleBackOrder.vue" 
import CarBackOrder from "@/views/content/CarBackOrder.vue" 
import VisitList from "@/views/content/VisitList.vue" 
import MarktingItem from "@/views/content/MarktingItem.vue"
import FinanceConfirm from "@/views/content/FinanceConfirm.vue"
import CarInfo from "@/views/content/CarInfo.vue"

Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: Index,
		children: [{
			path: '/',
			name: 'home',
			component: Home,
		},{
			path: '/system_menu',
			name: 'system_menu',
			component: SysTem,
		},{
			path: '/brand',
			name: 'brand',
			component: Brand,
		},{
			path: '/unit',
			name: 'unit',
			component: Unit,
		},{
			path: '/warehouse',
			name: 'warehouse',
			component: Warehouse,
		},{
			path: '/area1',
			name: 'area1',
			component: Area,
		},{
			path: '/client',
			name: 'client',
			component: Client,
		},{
			path: '/goods',
			name: 'goods',
			component: Goods,
		},{
			path: '/addGoods',
			name: 'addGoods',
			component: AddGoods,
		},{
			path:'/editGoods',
			name:"editGoods",
			component: EditGoods,
		},{
			path:'/in_order',
			name:"in_order",
			component: InOrder,
		},{
			path:'/out_order',
			name:"out_order",
			component: OutOrder,
		},{
			path:'/sale_order',
			name:"sale_order",
			component: SaleOrder,
		},{
			path:'/change_order',
			name:"change_order",
			component: ChangeOrder,
		},{
			path:'/members',
			name:"members",
			component: Members,
		},{
			path:'/query_stock',
			name:"query_stock",
			component: QueryStock,
		},{
			path:'/drivers',
			name:"drivers",
			component: Drivers,
		},{
			path:'/car_order',
			name:"car_order",
			component: CarOrder,
		},{
			path:'/print_design',
			name:"print_design",
			component: PrintDesign,
		},{
			path:'/print_design2',
			name:"print_design2",
			component: PrintDesign2,
		},{
			path:'/query_sale_stock',
			name:"query_sale_stock",
			component: QuerySaleStock,
		},{
			path:'/sale_back_order',
			name:"sale_back_order",
			component: SaleBackOrder,
		},{
			path:'/car_back_order',
			name:"car_back_order",
			component: CarBackOrder,
		},{
			path:'/visit_list',
			name:"visit_list",
			component: VisitList,
		},{
			path:'/markting_item',
			name:"markting_item",
			component: MarktingItem,
		},{
			path:'/finance_confirm',
			name:"finance_confirm",
			component: FinanceConfirm,
		},{
			path:'/car_info',
			name:"car_info",
			component: CarInfo,
		},]
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	}
]

const router = new VueRouter({
	routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {

  if (to.path === '/login') return next();
  // 获取token
  const token = sessionStorage.getItem('token')
  if (!token) {
    return next('/login')
  }
  next()
})

export default router
