<template>
	<div style="padding: 20px;">
		<el-dialog :visible.sync="dialogVisible2" width="50%" :before-close="handleClose2">
			<el-table :data="tableData2" highlight-current-row @current-change="handleSelectionChange">
				<el-table-column prop="name" label="商品名称"></el-table-column>
				<el-table-column prop="code" label="商品编码"></el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total2">
			</el-pagination>
			<el-button type="primary" @click="pushMList">确定</el-button>
		</el-dialog>
		
		<el-dialog :visible.sync="dialogVisible6" width="50%" :before-close="handleClose2">
			<el-table :data="tableData2" highlight-current-row @current-change="handleSelectionChange6">
				<el-table-column prop="name" label="商品名称"></el-table-column>
				<el-table-column prop="code" label="商品编码"></el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total2">
			</el-pagination>
			<el-button type="primary" @click="pushMList6">确定</el-button>
		</el-dialog>
		
		<el-dialog title="创建活动" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
			<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-button type="primary" @click="openGoods">活动商品</el-button>
				<el-table :data="detail">
					<el-table-column prop="name" label="商品名称"></el-table-column>
					<el-table-column label="选择单位">
						<template slot-scope="scope">
							<el-select v-model="ruleForm.goods_price_id" placeholder="请选择">
								<template v-for="item in scope.row.sku">
									<el-option :label="item.name" :value="item.id">
									</el-option>
								</template>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="满赠数量">
						<template slot-scope="scope">
							<el-input-number v-model="ruleForm.full_num" :min="1"
								label="描述文字">
							</el-input-number>
						</template>
					</el-table-column>

				</el-table>
				<el-button style="margin-top: 30px;" type="primary" @click="openGoods6">赠送商品</el-button>
				<el-table :data="detail2">
					<el-table-column prop="name" label="商品名称"></el-table-column>
					<el-table-column label="选择单位">
						<template slot-scope="scope">
							<el-select v-model="ruleForm.gift_goods_price_id" placeholder="请选择">
								<template v-for="item in scope.row.sku">
									<el-option :label="item.name" :value="item.id">
									</el-option>
								</template>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="赠送数量">
						<template slot-scope="scope">
							<el-input-number v-model="ruleForm.gift_num" :min="1"
								label="描述文字">
							</el-input-number>
						</template>
					</el-table-column>
				
				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="submitForm2('ruleForm')">提交</el-button>
					<el-button @click="resetForm2('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog :visible.sync="dialogVisible3" width="50%" :before-close="handleClose3">
			<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-table :data="orderDetail.detail">
					<el-table-column prop="goods_name" label="商品名称"></el-table-column>
					<el-table-column prop="goods_num" label="商品数量"></el-table-column>
					<el-table-column prop="goods_price" label="商品单价"></el-table-column>
					<el-table-column prop="unit2_unit1_name" label="单位换算"></el-table-column>
				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="handleClose3">关闭</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="openForm">创建活动</el-button>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="goods[0].bname" label="活动商品">
				</el-table-column>
				<el-table-column prop="goods[0].name" label="活动单位">
				</el-table-column>
				<el-table-column prop="full_num" label="满赠数量">
				</el-table-column>
				
				<el-table-column prop="gift_goods[0].bname" label="赠送商品">
				</el-table-column>
				<el-table-column prop="gift_goods[0].name" label="赠送单位">
				</el-table-column>
				<el-table-column prop="gift_num" label="赠送数量">
				</el-table-column>

	<!-- 			<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="openDetail(scope.row.id)" type="text" size="small">查看详情</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				multipleSelection: [],
				ruleForm: {
					marketing_id: 1, 
					goods_id: 0, 
					goods_price_id: 0, 
					full_num: 0, 
					gift_goods_id: 0, 
					gift_goods_price_id: 0, 
					gift_num: 0, 
					status: 1 
				},
				rules: {
					warehouse_id: [{
						required: true,
						message: '请选择仓库',
						trigger: 'blur'
					}],
					mid: [{
						required: true,
						message: '请选择用户',
						trigger: 'blur'
					}],
				},
				input: "",
				title: "",
				dialogVisible: false,
				dialogVisible2: false,
				dialogVisible3: false,
				dialogVisible6: false,
				total: 0,
				total2: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				pageInfo2: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
				tableData2: [],
				warehouse: [],
				members: [],
				goods: [],
				cateList: [],
				members: [],
				orderDetail: {
					warehouse_info: {
						name: ''
					},
					detail: []
				},
				
				detail: [],
				detail2: []
			}
		},

		mounted() {
			this.getData()
			this.getData2()
		},

		methods: {
			pushMList() {
				this.dialogVisible2 = false
			},
			
			pushMList6() {
				this.dialogVisible6 = false
			},
			
			openDetail(marketing_id) {
				this.$http.get("admin/marketingItem/listbymarketingid", {
					params: {marketing_id}
				}).then((res) => {
					this.orderDetail = res.data.data.data
					this.dialogVisible3 = true
				});
			},
			
			resetForm2(formName) {
				this.detail = []
				this.detail2 = []
				this.$refs[formName].resetFields();
			},
			
			submitForm2(formName) {
				if (this.detail.length <= 0) {
					this.$message({
						message: "请选择活动商品",
						type: "warning",
					});
					return
				}
				
				if (this.detail2.length <= 0) {
					this.$message({
						message: "请选择赠送商品",
						type: "warning",
					});
					return
				}
				
				this.ruleForm.goods_id = this.detail[0].id
				this.ruleForm.gift_goods_id = this.detail2[0].id
				
				this.$refs[formName].validate((valid) => {
					this.$http.post("admin/marketingItem/create", this.ruleForm).then((res) => {
						if (res.data.code == 200) {
							this.resetForm2("ruleForm");
							this.$message({
								message: "添加成功",
								type: "success",
							});
							this.dialogVisible = false;
							this.getData()
						}
					});
				});
			},
			handleSelectionChange(val) {
				this.detail = [val]
			},
			
			handleSelectionChange6(val) {
				this.detail2 = [val]
			},
			getData2() {
				this.$http
					.get("admin/goods/list", {
						params: this.pageInfo2,
					})
					.then((res) => {
						res.data.data.data.forEach(item => {
							item.goods_num = 1
							item.unit = null
						})
						this.tableData2 = res.data.data.data;
						this.total2 = res.data.data.count;
					});
			},

			openForm() {
				this.dialogVisible = true
			},
			
			openGoods() {
				this.dialogVisible2 = true
			},
			
			openGoods6() {
				this.dialogVisible6 = true
			},

			handleClose() {
				this.dialogVisible = false
				this.multipleSelection = []
				this.resetForm("ruleForm")
			},
			
			handleClose2() {
				this.multipleSelection = []
				this.dialogVisible2 = false
			},
			
			handleClose3() {
				this.dialogVisible3 = false
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post("admin/brand/create", this.ruleForm).then(res => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm")
								this.getData()
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.dialogVisible = false
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			
			handleSizeChange2(val) {
				this.pageInfo2.limit = val
				this.getData2()
			},
			
			handleCurrentChange2(val) {
				this.pageInfo2.page = val
				this.getData2()
			},
			
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/marketingItem/list", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.tableData.forEach((item) => {
						item.status == 2 ? item.status = true : item.status = false
					})
					this.total = res.data.data.count
				})
			},
		}
	}
</script>

<style>

</style>
