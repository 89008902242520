<template>
	<div>
		系统配置
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="less" scoped>

</style>
