<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'app'
	}
</script>

<style>
	html,
	body,
	#app {
		height: 100%;
		width: 100%;
		margin: 0;
		padding: 0;
		/* min-width: 1366px; */
	}
</style>
