<template>
  <div>
    <el-menu
      :default-active="activeIndex1"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="basicMsg">基本信息</el-menu-item>
      <el-menu-item index="priceManag">售价管理</el-menu-item>
      <el-menu-item index="imgDetails">图文详情</el-menu-item>
    </el-menu>

    <!-- 基本信息 -->
    <div class="basicMsg" v-if="componentsName === 'basicMsg'">
      <el-form
        class="basic-form"
        label-width="120px"
        :rules="rules"
        :model="goodsInfo"
        ref="basicInfo"
      >
        <el-form-item label="商品名称" prop="name">
          <el-input placeholder="请输入" v-model="goodsInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="商品助记名" prop="zjm">
          <el-input placeholder="请输入" v-model="goodsInfo.zjm"></el-input>
        </el-form-item>
        <el-form-item label="商品类别" prop="cid">
          <el-cascader
            style="width: 350px"
            v-model="value"
            :options="categoryOptions"
            :props="{
              expandTrigger: 'hover',
              value: 'id',
              label: 'name',
              children: 'Children',
              checkStrictly: true,
            }"
            @change="handleChange"
          >
          </el-cascader>
          <!-- 		<el-select v-model="basicForm.cid">
					
					<el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select> -->
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input placeholder="请输入" v-model="goodsInfo.spec"></el-input>
        </el-form-item>
        <el-form-item label="商品编号" prop="code">
          <el-input placeholder="请输入" v-model="goodsInfo.code"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="goodsInfo.status">
            <el-option
              v-for="item in statusOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原产地" prop="place">
          <el-input placeholder="请输入" v-model="goodsInfo.place"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-select v-model="goodsInfo.brand">
            <el-option
              v-for="item in brandOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1">单位信息</el-menu-item>
      </el-menu>
      <div class="cards">
        <el-card>
          <div style="text-align: center">小单位</div>
          <el-form
            label-width="100px"
            :rules="rules"
            :model="goodsInfo"
            ref="unitInfo"
            style="margin-top: 20px"
          >
            <el-form-item prop="unit1_id" label="小单位类型">
              <el-select v-model="goodsInfo.unit1_id" value-key="value">
                <el-option
                  v-for="item in unitLists1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="unit1_code" label="小单位条码">
              <el-input
                v-model="goodsInfo.unit1_code"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <div style="text-align: center">大单位</div>
          <el-form
            label-width="100px"
            :model="goodsInfo"
            ref="unit2Info"
            :rules="rules"
            style="margin-top: 20px"
          >
            <el-form-item prop="unit2_id" label="大单位">
              <el-select
                v-model="goodsInfo.unit2_id"
                value-key="value"
                clearable
              >
                <el-option
                  v-for="item in unitLists2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="unit2_code" label="大单位条码">
              <el-input
                v-model="goodsInfo.unit2_code"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item prop="conversion" label="大单位换算">
              <el-input
                :disabled="!goodsInfo.unit1_id ? true : false"
                v-model="goodsInfo.unit2_unit1"
                placeholder="=请输入"
              >
                <template slot="append" v-if="goodsInfo.unit1_id">{{
                  computedS
                }}</template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 售价管理 -->
    <div class="priceManag" v-else-if="componentsName === 'priceManag'">
      <el-card style="width: 100%; box-sizing: border-box">
        <el-table
          :data="goodsInfo.price"
          style="width: 100%; box-sizing: border-box"
          :border="true"
          :header-cell-style="{ background: '#f3f3f3' }"
        >
          <el-table-column prop="" label="单位" width="120" align="center">
            <template slot-scope="scope">
              {{ cal(scope.row.unit_id) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="批发价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input type="number" class="item" v-model.number="scope.row.price" ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price1"
            label="零售价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input type="number" class="item" v-model.number="scope.row.price1"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price2"
            label="最低零售价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input type="number" class="item" v-model.number="scope.row.price2"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price3"
            label="进价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input  type="number" class="item" v-model.number="scope.row.price3"></el-input>
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
      </el-card>
    </div>

    <!-- 图片详情 -->
    <div class="imgDetails" v-else>
      <!-- 顶部图片 -->
      <div
        style="
          display: flex;
          margin-top: 20px;
          padding-left: 20px;
          box-sizing: border-box;
        "
      >
        <!-- 商品图片 -->
        <div style="margin-right: 50px; width: 600px">
          <div style="line-height: 40px; margin-left: 10px"> <span style="color: red;margin-right: 3px;font-size: 14px;">*</span>商品图片</div>
          <div style="position: relative; width: fit-content">
            <div
              v-if="!imgList[0]"
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                text-align: center;
                background-color: #0e4183;
                color: white;
                border-radius: 0 0 6px 6px;
              "
            >
              点击上传图片
            </div>
            <el-upload
              action="https://api2022.ezagooad.com/lsm/api/upload/ossfiles"
              :file-list="imgList"
              :limit="3"
              ref="upload"
              :on-success="handleAvatarSuccess"
              list-type="picture-card"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </div>

        <!-- 封面 -->
        <div>
          <div style="line-height: 40px"> <span style="color: red;margin-right: 3px;font-size: 14px;">*</span>封面</div>
          <div style="position: relative">
            <div
              v-if="!goodsInfo.detail.photo"
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                text-align: center;
                background-color: #0e4183;
                color: white;
                border-radius: 0 0 6px 6px;
              "
            >
              点击上传封面
            </div>
            <el-upload
              action="https://api2022.ezagooad.com/lsm/api/upload/ossfiles"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
            >
              <img
                v-if="goodsInfo.detail.photo"
                :src="goodsInfo.detail.photo"
                class="avatar"
              />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </div>

      <!-- 商品详情 -->
      <div style="margin: 20px; margin-top: 50px; box-sizing: border-box">
        <div style="line-height: 40px; margin-left: 10px"><span style="color: red;margin-right: 3px;font-size: 14px;">*</span>商品详情</div>
        <el-card>
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 300px; overflow-y: hidden"
            v-model="goodsInfo.detail.description"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </el-card>
      </div>
    </div>

    <!-- 下方按钮区 -->
    <div class="btns">
      <el-button
        v-if="!(componentsName === 'basicMsg')"
        type="primary"
        @click="back()"
        >上一步</el-button
      >
      <el-button type="primary" @click="submit()">{{
        this.componentsName === "imgDetails" ? "提交" : "下一步"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "addGoods",
  components: {
    Editor,
    Toolbar,
  },

  // 计算出单位名
  computed: {
    computedS() {
      let temp;
      this.unitLists1.forEach((itm) => {
        if (itm.id === this.goodsInfo.unit1_id) {
          temp = itm.name;
        }
      });
      return temp;
    },
  },
  data() {
    return {
      goodsId:0,
      goodsInfo: {
        cid: 23,
        code: "01002",
        detail: {
          description: "<p>好看的玩偶</p>",
          photo:
            "https://xindian.oss-cn-beijing.aliyuncs.com/covers/c631eca5e59b5a2ad07ae677f8aabd79.jpg",
          photos:
            "https://xindian.oss-cn-beijing.aliyuncs.com/covers/affb1ff53fdea695e95b4d8e5cf43ae3.jpg,https://xindian.oss-cn-beijing.aliyuncs.com/covers/81272166eb1b6a69e2e8a5c5a46402b9.jpg",
        },
        name: "公仔",
        place: "湖南",
        price: [
          { unit_id: 13, price: 10, price1: 30, price2: 25, price3: 10 },

          { unit_id: 7, price: 75, price1: 250, price2: 250, price3: 75 },
        ],
        spec: "只",
        status: 1001,
        unit1_code: "13131",
        unit1_id: 13,
        unit2_code: "13134",
        unit2_id: 7,
        unit2_unit1: 8,
        zjm: "玩偶",
      },

      componentsName: "basicMsg",
      activeIndex1: "basicMsg",
      rules: {
        name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
        unit2_id: [
          {
            required: true,
            message: "请选择大单位类型",
            trigger: "blur",
          },
        ],
        unit1_id: [
          {
            required: true,
            message: "请选择小单位类型",
            trigger: "blur",
          },
        ],

        // brand: [{
        // 	required: true,
        // 	message: "请选择商品品牌",
        // 	trigger: "blur"
        // }],
        zjm: [
          {
            required: true,
            message: "请输入商品助记名",
            trigger: "blur",
          },
        ],
        cid: [
          {
            required: true,
            message: "请选择商品类别",
            trigger: "blur",
          },
        ],
      },
      brandOptions: [
        {
          name: "小米",
          id: 1,
        },
        {
          name: "华为",
          id: 2,
        },
      ],
      categoryOptions: [],
      statusOptions: [
        {
          name: "正常",
          id: 1001,
        },
        {
          name: "异常",
          id: 1002,
        },
      ],
      unitLists1: [],
      unitLists2: [],

      pageInfo1: {
        page: 1,
        limit: 10000,
        unit_type: 1,
      },

      pageInfo2: {
        page: 1,
        limit: 10000,
        unit_type: 2,
      },
      value: [],

      // 图文详情的数据
      imgList: [],
      // 富文本数据
      editor: null,
      toolbarConfig: {
        excludeKeys: ["fullScreen"],
      },
      editorConfig: {
        placeholder: "请输入内容...",

        //看这里
        //-----------------------------------
        MENU_CONF: {
          uploadImage: {
            //server必须要配置正确
            server: "https://api2022.ezagooad.com/lsm/api/upload/ossfiles",

            maxFileSize: 20 * 1024 * 1024, // 1M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: [],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            fieldName: "file",
            customInsert(res, insertFn) {
              // JS 语法
              // res 即服务端的返回结果
              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(res.data);
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },

  mounted() {
    this.goodsId = this.$route.query.id
	this.$http.get("/admin/goods/info", {
			params: {
				id: this.goodsId
			}
		}).then((res) => {
			console.log(res.data.data)
			this.goodsInfo = res.data.data
			// 根据商品id拿到商品信息后,将图片组变成数组展示
			this.goodsInfo.detail.photos.split(",").forEach((item) => {
			  this.imgList.push({ url: item });
			});
		})

    this.getCate();
    this.getUnit1();
    this.getUnit2();
  },

  methods: {
    // 删除图片组中的图片
    handleRemove(file){
      let temp
      this.imgList.forEach((itm,idx)=>{
        if(itm.url === file.url){
          temp = idx
        }
      })
      this.imgList.splice(temp,1)
    },
    // 获取单位名字
    cal(s) {
      let temp;
      this.unitLists1.forEach((itm) => {
        if (itm.id === s) {
          temp = itm.name;
        }
      });
      this.unitLists2.forEach((itm) => {
        if (itm.id === s) {
          temp = itm.name;
        }
      });
      return temp;
    },

    back() {
      if (this.componentsName === "priceManag") {
        this.activeIndex1 = this.componentsName = "basicMsg";
      } else {
        this.activeIndex1 = this.componentsName = "priceManag";
      }
    },

    handleSelect(index) {
      this.componentsName = index;
    },

    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(editor.getMenuConfig("uploadImage"));
    },
    handleAvatarSuccess(res, file) {
      this.imgList.push({url:res.data});

      const uploadDom = this.$refs["upload"];
      if (this.imgList.length === 3) {
        uploadDom.$children[1].$el.style.display = "none";
      } else {
        uploadDom.$children[1].$el.style.display = "";
      }
    },
    handleAvatarSuccess1(res, file) {
      this.goodsInfo.detail.photo = res.data;
    },

    handleChange(value) {
      this.value = value;
      this.goodsInfo.cid = value[value.length - 1];
    },
    getUnit1() {
      this.$http
        .get("admin/unit/list", {
          params: this.pageInfo1,
        })
        .then((res) => {
          this.unitLists1 = res.data.data.data;
        });
    },

    getUnit2() {
      this.$http
        .get("admin/unit/list", {
          params: this.pageInfo2,
        })
        .then((res) => {
          this.unitLists2 = res.data.data.data;
        });
    },

    getCate() {
      this.$http.get("admin/cate/list").then((res) => {
        this.categoryOptions = res.data.data;
      });
    },
    submit() {
      switch (this.componentsName) {
        case "basicMsg":
          this.saveBasic();
          break;
        case "priceManag":
          this.savePrice();
          break;
        case "imgDetails":
          this.submitAll();
          break;
      }
    },

    // 保存基本信息
    saveBasic() {
      this.$refs.basicInfo.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.$refs.unitInfo.validate(async (valid1)=>{
          if(!valid1){
            return
          }
          this.$refs.unit2Info.validate(async (valid2)=>{
            if(!valid2){
              return
            }
            this.componentsName = this.activeIndex1 = "priceManag";
          })
          
        })
      });
    },

    savePrice() {
      this.componentsName = this.activeIndex1 = "imgDetails";
    },
    submitAll(){
      if(!this.goodsInfo.detail.photo || this.goodsInfo.detail.description==='<p><br></p>' || !this.goodsInfo.detail.description || !this.imgList.length){
        this.$message.info('请将所有必填项填写完整')
        return
      }
	  console.log(this.goodsInfo)
      this.goodsInfo.detail.photos = this.imgList.map(item=>item.url).join(',')
	  this.$http.post("admin/goods/editgoods", this.goodsInfo).then((res) => {
	    if (res.data.code == 200) {
	      this.$message({
	        message: "商品保存成功",
	        type: "success",
	      });  
	      // 关闭创建商品的窗口
	      this.$EventBus.$emit('close','editGoods')
	    }
	  });
    }
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
.btns {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
}
.basicMsg {
  .basic-form {
    display: block;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .basic-form::after {
    width: 470px;
    content: "";
  }

  .el-select {
    width: 350px !important;
  }

  .el-form-item {
    width: fit-content;
  }

  .el-input {
    width: 350px !important;
  }

  .cards {
    display: flex;
    padding: 50px;
    width: 100%;
    box-sizing: border-box;
  }

  .el-card {
    width: 550px;
    margin-right: 50px;
  }
}

.priceManag {
  .item {
    width: 100px;
  }
  .txt {
    line-height: 24px;
    padding: 0 9px;
    box-sizing: border-box;
  }

  .el-input {
    border: none;
  }
  input.el-input__inner {
    border: none !important;
  }
}

.imgDetails {
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
</style>
