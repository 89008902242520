<template>
	<div class="home">
		<!-- <div class="header"> -->
			<!-- <div class="open">
				<i v-if="!isCollapse" class="el-icon-s-fold" @click="changMenu"></i>
				<i v-else class="el-icon-s-unfold" @click="changMenu"></i>
			</div> -->
			<Header></Header>
<!-- 			<div>
				<el-breadcrumb separator="/">
				  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				  <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
				  <el-breadcrumb-item>活动列表</el-breadcrumb-item>
				  <el-breadcrumb-item>活动详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div> -->

		<!-- </div> -->
		<!-- <el-divider></el-divider> -->
		<!-- <Header></Header> -->
		<!-- <router-view></router-view> -->
	</div>
</template>

<script>
	import Header from "./HeaderView.vue"
	export default {
		props: ["isCollapse"],
		components: {
			Header
		},
		methods: {
			changMenu() {
				this.$emit("changeShow")
			}
		}
	}
</script>

<style lang="less" scoped>

	.header {
		width: 100%;
		height: 64px;
		background: #ffffff;
		// line-height: 64px;
		padding: 0 12px;
		.open {
			font-size: 18px;
		}
	}
</style>