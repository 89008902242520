import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './plugins/charts.js'
import axios from 'axios'

import KrPrintDesigner from "kr-print-designer";
import "kr-print-designer/lib/kr-print-designer.css";

Vue.use(KrPrintDesigner);

Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue()

// 导入全局样式
import "../src/assets/css/global.css"
import "../src/assets/css/iconfont.css"
import store from './store'

// 配置請求的路径/zchlweb
axios.defaults.baseURL = "/"
// axios请求拦截
axios.interceptors.request.use((res) => {
	// 挂载Authorization请求头
	res.headers.Authorization = sessionStorage.getItem("token")

	if (res.url.includes('goods/create') || res.url.includes('order/inbound') || res.url.includes('goods/editgoods') || res.url.includes('order/transfers') || res.url.includes('order/sale') || res.url.includes('order/carback')) {
		res.headers['Content-Type'] = 'application/json;charset:utf-8;'
	} else {
		res.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
	}
	return res
})


axios.interceptors.response.use(function(res) {
	return res}, function(error) {
	if(error.response.data.code == 401){
		router.replace("/login")
		return
	}
	return Promise.reject(error); });

Vue.prototype.$http = axios



import VueAMap from 'vue-amap'
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
	key: '69bc552f6dc8063932ebc71caf3dec11', // 高德的key
	// 插件集合
	plugin: [
		'AMap.CircleEditor', // 圆形编辑器插件
		"AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
		"AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.CitySearch",
	],
	// 高德 sdk 版本，默认为 1.4.4
	v: '1.4.4'
});
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode: 'fdd379115df916167a9fc310bdd94853',
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
