<template>
	<div style="padding: 20px;">
		<el-dialog :title="ruleForm.id == 0 ? '添加客户': '修改客户'" :visible.sync="dialogVisible" width="60%"
			:before-close="handleClose">
			<el-form :inline="true" label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm"
				label-width="100px" class="demo-ruleForm">
				<el-form-item label="公司名称" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="客户编码" prop="code">
					<el-input v-model="ruleForm.code"></el-input>
				</el-form-item>
				<el-form-item label="客户名称" prop="boss_name">
					<el-input v-model="ruleForm.boss_name"></el-input>
				</el-form-item>
				<el-form-item label="客户手机" prop="boss_mobile">
					<el-input v-model="ruleForm.boss_mobile"></el-input>
				</el-form-item>
				<el-form-item label="客户备注" prop="remark">
					<el-input v-model="ruleForm.remark"></el-input>
				</el-form-item>
				<el-form-item label="助记码" prop="memory_code">
					<el-input v-model="ruleForm.memory_code"></el-input>
				</el-form-item>
				<el-form-item label="所属人员" prop="mid">
					<el-select v-model="ruleForm.mid" placeholder="请选择">
						<el-option v-for="item in userList" :key="item.id" :label="item.real_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- 				<el-form-item label="客户纬度" prop="latitude">
					<el-input v-model="ruleForm.latitude"></el-input>
				</el-form-item>
				<el-form-item label="客户经度" prop="longitude">
					<el-input v-model="ruleForm.longitude"></el-input>
				</el-form-item> -->

				<el-form-item label="客户地址" prop="district">
					<el-cascader v-model="value" :options="address"
						:props="{ expandTrigger: 'hover', value: 'name', label: 'name' }" @change="handleChange">
					</el-cascader>
				</el-form-item>
					<el-form-item label="详细地址" prop="address">
					<el-input v-model="ruleForm.address"></el-input>
				</el-form-item>
				<el-form-item label="所属区域" prop="area">
					<el-select v-model="ruleForm.area" placeholder="请选择">
						<el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="客户状态">
					<el-radio-group v-model="ruleForm.status">
						<el-radio :label="1">正常</el-radio>
						<el-radio :label="2">禁用</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- 地图 -->
				<div style="width: 450px; height: 500px;">
					<Map ref="map" @updateLocation="updateLocation"/>
				</div>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="dialogVisible = true">添加客户</el-button>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="code" label="客户编码">
				</el-table-column>
				<el-table-column prop="name" label="公司名称">
				</el-table-column>
				<el-table-column prop="boss_name" label="客户名称">
				</el-table-column>
				<el-table-column prop="boss_mobile" label="客户电话">
				</el-table-column>
				<el-table-column label="地址">
					<template slot-scope="scope">
						{{scope.row.provinces + scope.row.city + scope.row.district + scope.row.address}}
					</template>
				</el-table-column>
				<el-table-column prop="member_info.real_name" label="所属用户">
				</el-table-column>
				<el-table-column prop="memory_code" label="助记码">
				</el-table-column>
				<el-table-column label="经纬度">
					<template slot-scope="scope">
						<el-row>
							<span>{{scope.row.latitude}}</span>
						</el-row>
						<el-row>
							<span>{{scope.row.longitude}}</span>
						</el-row>
					</template>
				</el-table-column>

				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" type="text" size="small">编辑</el-button>
						<!-- <el-button @click="delRow(scope.row)" type="text" size="small">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>


<script>
	import address from "@/assets/json/address.json"
	import Map from '@/components/aliMap.vue'
	export default {
		components: {
			Map
		},

		data() {
			return {
				address,
				value: [],
				ruleForm: {
					id: 0,
					name: "",
					status: 1,
					code: "",
					memory_code: "",
					boss_name: "",
					boss_mobile: "",
					provinces: "",
					city: "",
					district: "",
					address: "",
					remark: "",
					area: null,
					mid: null,
					latitude: "",
					longitude: ""
				},
				rules: {
					name: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}],
					memory_code: [{
						required: true,
						message: '请输入助记码',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入客户编码',
						trigger: 'blur'
					}],
					boss_name: [{
						required: true,
						message: '请输入客户名称',
						trigger: 'blur'
					}],
					boss_mobile: [{
						required: true,
						message: '请输入客户手机号码',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入客户详细地址',
						trigger: 'blur'
					}],
					district: [{
						required: true,
						message: '请选择客户地址',
						trigger: 'blur'
					}],
					remark: [{
						required: true,
						message: '请输入客户备注',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'blur'
					}],
					mid: [{
						required: true,
						message: '请选择所属人员',
						trigger: 'blur'
					}],
					latitude: [{
						required: true,
						message: '请输入客户纬度',
						trigger: 'blur'
					}],
					longitude: [{
						required: true,
						message: '请输入客户经度',
						trigger: 'blur'
					}],
				},
				input: "",
				title: "",
				dialogVisible: false,
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				pageInfo2: {
					page: 1,
					limit: 1000
				},
				currentPage: 1,
				tableData: [],
				areaList: [],
				userList: [],
			}
		},

		mounted() {
			this.getData()
			this.getArea()
			this.getUser()
		},

		methods: {

			updateLocation(Addr) {
				this.ruleForm.province = Addr.province
				this.ruleForm.city = Addr.city === '' ? Addr.province:Addr.city
				this.ruleForm.district = Addr.district
				this.ruleForm.latitude = Addr.lat
				this.ruleForm.location = Addr.lon
				this.ruleForm.address = Addr.orgAddr
				this.value = [Addr.province, Addr.city === '' ? Addr.province:Addr.city, Addr.district]
				// console.log('位置信息：', Addr)
			},

			getArea() {
				this.$http.get("admin/area/list", {
					params: this.pageInfo2
				}).then(res => {
					this.areaList = res.data.data.data
				})
			},

			getUser() {
				this.$http.get("admin/member/list", {
					params: this.pageInfo2
				}).then(res => {
					this.userList = res.data.data.data
				})
			},

			handleClose() {
				this.dialogVisible = false
				this.value = []
				this.resetForm("ruleForm")
			},

			handleChange(value) {
				this.value = value
				this.ruleForm.provinces = value[0]
				this.ruleForm.city = value[1]
				this.ruleForm.district = value[2]
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.ruleForm.id == 0) {
							this.$http.post("admin/client/create", this.ruleForm).then(res => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						} else {
							this.$http.post("admin/client/edit", this.ruleForm).then(res => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '修改成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						}


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			delRow(row) {
				this.$http.post("admin/client/del", {
					id: row.id
				}).then(res => {
					this.getData()
				})
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/client/list", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},

			editRow(row) {
				this.$http.get("admin/client/listbyid", {
					params: {
						id: row.id
					}
				}).then(res => {
					this.value = [res.data.data.provinces, res.data.data.city, res.data.data.district]
					this.dialogVisible = true
					this.ruleForm = res.data.data
					// this.$http.get(`/v3/geocode/geo?address=${res.data.data.provinces+ res.data.data.city+ res.data.data.district}&key=376b1851790d83cb0a6575399daa20de`).then(res2 => {
					// 	console.log(res2)
					// 	let locationList = res2.geocodes[0].location.split(",")
					// 	this.resetForm.latitude = locationList[1]
					// 	this.resetForm.longitude = locationList[0]
					// }).catch(err=> {
					// 	console.log(err)
					// })
				})
			},
		}
	}
</script>

<style>

</style>
