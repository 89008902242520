<template>
	<div style="padding: 20px;">
		
		<!-- 详情 -->
		<el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="imgs">
				<img v-for="(index, item) in imgList" :key="index" :src="item" alt="" style="width: 150px; height: 150px; margin-right: 30px;">
			</div>
			<el-button type="primary" @click="handleClose">关闭</el-button>
		</el-dialog>
		
		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column label="拜访时间">
					<template slot-scope="scope">
						{{scope.row.begin + " - " + scope.row.end}}
					</template>
				</el-table-column>
				<el-table-column prop="money" label="金额">
				</el-table-column>
				<el-table-column prop="area" label="地址">
				</el-table-column>
				<el-table-column prop="customer_name" label="客户">
				</el-table-column>
				
				<el-table-column prop="salesman_name" label="业务员">
				</el-table-column>
				<el-table-column label="图片">
					
					<template slot-scope="scope">
						<el-button @click="openImages(scope.row.imgs)" type="text" size="small">门店图片</el-button>
						<el-button @click="openImages(scope.row.imgs1)" type="text" size="small">陈列图片</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
				imgList: []
			}
		},

		mounted() {
			this.getData()
		},

		methods: {
			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},
			
			handleClose() {
				this.dialogVisible = false
				this.imgList = []
			},
			
			openImages(imgs) {
				if(!imgs){
					this.$message({
						message: '暂无图片',
						type: 'warning'
					});
					return
				}
				this.temp = imgs.split(",")
				this.temp.forEach(item => {
					this.imgList.push("" + item)
				})
				this.dialogVisible = true
			},
			
			getData() {
				this.$http.get("admin/visit/list", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
		}
	}
</script>

<style>
.imgs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 30px;
}
	
	
</style>
