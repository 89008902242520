<template>
	<div style="padding: 20px;">
		
		<el-dialog :visible.sync="dialogVisible2" width="50%" :before-close="handleClose2">
			<el-table :data="tableData2" border ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="商品名称"></el-table-column>
				<el-table-column prop="code" label="商品编码"></el-table-column>
				<el-table-column prop="stock.quantity" label="大单位库存"></el-table-column>
				<el-table-column prop="stock.quantity_little" label="小单位库存"></el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total2">
			</el-pagination>
			<el-button type="primary" @click="pushMList">确定</el-button>
		</el-dialog>
		
		<el-dialog title="创建订单" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
			<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="出仓库" prop="out_warehouse_id">
					<el-select v-model="ruleForm.out_warehouse_id">
						<el-option v-for="item in warehouse" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入仓库" prop="in_warehouse_id">
					<el-select v-model="ruleForm.in_warehouse_id">
						<el-option v-for="item in warehouse" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-button type="primary" @click="openGoods">导入商品</el-button>
				<el-table :data="multipleSelection">
					<el-table-column prop="name" label="商品名称" width="100"></el-table-column>
					<el-table-column label="选择单位">
						<template slot-scope="scope">
							<el-select v-model="multipleSelection[scope.$index].unit" placeholder="请选择">
								<template v-for="item in multipleSelection[scope.$index].sku">
									<el-option :label="item.name" :value="item.unit_id + ',' + item.unit_type">
									</el-option>
								</template>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="入库数量">
						<template slot-scope="scope">
							<el-input-number v-model="multipleSelection[scope.$index].goods_num" :min="1"
								label="描述文字">
							</el-input-number>
						</template>
					</el-table-column>
					<el-table-column width="100" prop="stock.quantity" label="大单位库存"></el-table-column>
					<el-table-column width="100" prop="stock.quantity_little" label="小单位库存"></el-table-column>
				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="submitForm2('ruleForm')">提交</el-button>
					<el-button @click="resetForm2('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<!-- 详情 -->
		<el-dialog :visible.sync="dialogVisible3" width="50%" :before-close="handleClose3">
			<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
			<!-- 	<el-form-item label="仓库">
					<el-input :value="orderDetail.warehouse_info.name" disabled></el-input>
				</el-form-item> -->
				<el-table :data="orderDetail.detail">
					<el-table-column prop="goods_name" label="商品名称"></el-table-column>
					<el-table-column prop="goods_num" label="商品数量"></el-table-column>
					<el-table-column prop="goods_price" label="商品单价"></el-table-column>
					<el-table-column prop="unit2_unit1_name" label="单位换算"></el-table-column>
				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="handleClose3">关闭</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="openForm">创建订单</el-button>
				</el-col>
			</el-row>
		</el-card>
		

		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="created_at" label="创建日期">
				</el-table-column>
				<el-table-column prop="order_no" label="订单号">
				</el-table-column>
				<el-table-column prop="out_warehouse.name" label="调出仓库">
				</el-table-column>
				<el-table-column prop="in_warehouse.name" label="调入仓库">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="openDetail(scope.row.order_no)" type="text" size="small">查看详情</el-button>
						<el-button @click="printView(scope.row.id)" type="text" size="small">打印预览</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				multipleSelection: [],
				
				ruleForm: {
					out_warehouse_id: null,
					in_warehouse_id: null,
				},
				rules: {
					out_warehouse_id: [{
						required: true,
						message: '请选择出仓库',
						trigger: 'blur'
					}],
					in_warehouse_id: [{
						required: true,
						message: '请选择入仓库',
						trigger: 'blur'
					}],
				},
				
				dialogVisible: false,
				dialogVisible2: false,
				dialogVisible3: false,
				total: 0,
				total2: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				pageInfo2: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
				tableData2: [],
				warehouse: [],
				members: [],
				goods: [],
				orderDetail: {
					warehouse_info: {
						name: ""
					},
					deatil: []
				},
				template: null,
			}
		},

		mounted() {
			this.$http.get("admin/warehouse/list", {
				params: this.pageInfo
			}).then(res => {
				this.warehouse = res.data.data.data
			})
			this.getData()
			this.getTemplate()
			// this.getData2()
		},

		methods: {
			pushMList() {
				this.dialogVisible2 = false
			},
			
			handleClose2() {
				this.multipleSelection = []
				this.ruleForm.out_warehouse_id = 0
				this.dialogVisible2 = false
			},
			
			handleCurrentChange2(val) {
				this.pageInfo2.page = val
				this.getData2()
			},
			
			handleSizeChange2(val) {
				this.pageInfo2.limit = val
				this.getData2()
			},
			
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			
			getData2() {
				console.log(this.ruleForm.out_warehouse_id)
				if(!this.ruleForm.out_warehouse_id) {
					this.$message({
						message: '请选择调库仓库',
						type: 'error'
					});
					return
				}
				console.log(this.ruleForm.out_warehouse_id)
				this.pageInfo2.stock_id = this.ruleForm.out_warehouse_id
				this.$http
					.get("admin/goods/show", {
						params: this.pageInfo2,
					})
					.then((res) => {
						res.data.data.data.forEach(item => {
							item.goods_num = 1
							item.unit = null
						})
						this.tableData2 = res.data.data.data;
						this.total2 = res.data.data.count;
						this.dialogVisible2 = true
					});
			},
			
			openGoods() {
				this.getData2()
			},
			
			openForm() {
				this.dialogVisible = true
			},
			
			handleClose() {
				this.dialogVisible = false
				this.multipleSelection = []
				this.resetForm("ruleForm")
			},
			
			handleClose3() {
				this.dialogVisible3 = false
			},
			
			openDetail(order_no) {
				this.$http.get("admin/order/detail", {
					params: {order_no, type: 2}
				}).then((res) => {
					this.orderDetail = res.data.data.data
					this.dialogVisible3 = true
				});
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post("admin/brand/create", this.ruleForm).then(res => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm")
								this.getData()
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.dialogVisible = false
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			submitForm2(formName) {
				if (this.multipleSelection.length <= 0) {
					this.$message({
						message: "请选择商品",
						type: "warning",
					});
					return
				}
				
				if (this.ruleForm.out_warehouse_id === this.ruleForm.in_warehouse_id) {
					this.$message({
						message: "出入仓库不能一样",
						type: "warning",
					});
					return
				}

				this.$refs[formName].validate((valid) => {
					if (valid) {
						let detail = []
						
						this.multipleSelection.forEach(item => {
							let units = item.unit.split(",")
							detail.push({
								"goods_id": item.id, 
								"unit_id": parseInt(units[0]), 
								"unit_type": parseInt(units[1]), 
								"goods_num": item.goods_num
							})
						})
						this.ruleForm.detail = detail
						console.log(this.ruleForm)
						this.$http.post("admin/order/transfers", this.ruleForm).then((res) => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm");
								this.$message({
									message: "调库成功",
									type: "success",
								});
								this.dialogVisible = false;
								this.getData()
							}else{
								this.$message({
									message: res.data.msg,
									type: "error",
								});
							}
						}).catch((err) => {
							this.$message({
								message: err.response.data.msg,
								type: "error",
							});
						});
					} else {
						this.$message({
							message: "调库失败",
							type: "error",
						});
						return false;
					}
				});
			},
			
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/order/transferslist", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
			
			printView(order_no) {
			    this.$http.get("/admin/print/transfers", {
			        params: {
			            order_id: order_no
			        }
			    }).then(res => {
			        this.$lodop.preview(JSON.parse(this.template), res.data.data);
			    })
			},
			
			getTemplate() {
			    this.$http.get("admin/report/templatelistbyid", {
			        params: {
			            id: 6
			        }
			    }).then(res => {
			        this.template = res.data.data.template
			    })
			},
		}
	}
</script>

<style>

</style>
