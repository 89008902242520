<template>
	<div style="padding: 20px;">
		<el-dialog :title="ruleForm.id == 0 ? '添加仓库': '修改仓库'" :visible.sync="dialogVisible" width="30%"
			:before-close="handleClose">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="名称" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-switch v-model="ruleForm.status"></el-switch>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="dialogVisible = true">添加仓库</el-button>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="name" label="名称">
				</el-table-column>
				<el-table-column prop="status" label="状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" disabled></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" type="text" size="small">编辑</el-button>
						<el-button @click="delRow(scope.row)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ruleForm: {
					id: 0,
					name: '',
					status: true,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入品牌名称',
						trigger: 'blur'
					}],
				},
				input: "",
				title: "",
				dialogVisible: false,
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: []
			}
		},

		mounted() {
			this.getData()
		},

		methods: {
			handleClose() {
				this.dialogVisible = false
				this.resetForm("ruleForm")
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.status ? this.ruleForm.status = 2 : this.ruleForm.status = 1
						console.log(this.ruleForm)
						if (this.ruleForm.id == 0) {
							this.$http.post("admin/warehouse/create", this.ruleForm).then(res => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						} else {
							this.$http.post("admin/warehouse/edit", this.ruleForm).then(res => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '修改成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						}


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			delRow(row) {
				this.$http.post("admin/warehouse/del", {
					id: row.id
				}).then(res => {
					this.getData()
				})
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/warehouse/list", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.tableData.forEach((item) => {
						item.status == 2 ? item.status = true : item.status = false
					})
					this.total = res.data.data.count
				})
			},

			editRow(row) {
				this.$http.get("admin/warehouse/listbyid", {
					params: {
						id: row.id
					}
				}).then(res => {
					this.ruleForm = res.data.data
					this.ruleForm.status == 2 ? this.ruleForm.status = true : this.ruleForm.status = false
					this.dialogVisible = true
				})
			},
		}
	}
</script>

<style>

</style>
