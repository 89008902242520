<template>
	<div style="padding: 20px;">
		<el-dialog :title="ruleForm.id == 0 ? '添加司机': '修改司机'" :visible.sync="dialogVisible" width="30%"
			:before-close="handleClose">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="用户名" prop="user_name">
					<el-input v-model="ruleForm.user_name"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名" prop="real_name">
					<el-input v-model="ruleForm.real_name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="ruleForm.remark"></el-input>
				</el-form-item>
				<el-form-item label="在岗状态">
					<el-switch v-model="ruleForm.work_status"></el-switch>
				</el-form-item>
				<el-form-item label="选择车辆">
					<el-select v-model="ruleForm.car_id">
						<el-option v-for="item in carList" :key="item.id" :label="item.car_code" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="头像" prop="avatar">
					<el-upload class="avatar-uploader" action="https://app.api.ezagooad.com/lsm/api/upload/ossfiles"
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
		
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="dialogVisible = true">添加司机</el-button>
				</el-col>
			</el-row>
		</el-card>
		
		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="real_name" label="姓名">
				</el-table-column>
				<el-table-column prop="user_name" label="用户名">
				</el-table-column>
				<el-table-column prop="work_status" label="在岗状态">
					<template slot-scope="scope">
						{{scope.row.work_status === 1 ? '在岗': '不在岗'}}
					</template>
				</el-table-column>
				<el-table-column prop="car_code" label="车辆">
				</el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.avatar" style="width: 100px; height: 100xp;">
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" type="text" size="small">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				fileList: [],
				
				ruleForm: {
					id: 0,
					user_name: "",
					real_name: "",
					phone: "",
					remark: "",
					avatar: "",
					user_type: 2,
					car_id: null,
					work_status: 1,
				},
				
				rules: {
					user_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					remark: [{
						required: true,
						message: '请输入备注',
						trigger: 'blur'
					}],
					avatar: [{
						required: true,
						message: '请输上传头像',
						trigger: 'blur'
					}],
				},
				
				input: "",
				title: "",
				dialogVisible: false,
				dialogVisible3: false,
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
				warehouse: [],
				members: [],
				goods: [],
				carList: [],
				orderDetail: {
					warehouse_info: {
						name: ""
					},
					deatil: []
				}
			}
		},

		mounted() {
			this.getData()
			this.getCar()
		},

		methods: {
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data
			},
			
			getCar() {
				this.$http.get("admin/car/list", {
					params: this.pageInfo
				}).then(res => {
					this.carList = res.data.data.data
				})
			},
			
			handleClose3() {
				this.dialogVisible3 = false
			},
			
			handleClose() {
				this.dialogVisible = false
				this.resetForm("ruleForm")
			},
			
			editRow(row) {
				this.$http.get("admin/member/listbyid", {
					params: {id: row.id}
				}).then(res => {
					this.ruleForm = res.data.data
					this.dialogVisible = true
				})
			},
			
			openDetail(order_no) {
				this.$http.get("admin/order/detail", {
					params: {order_no, type: 1}
				}).then((res) => {
					this.orderDetail = res.data.data.data
					this.dialogVisible3 = true
				});
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ruleForm.work_status ? this.ruleForm.work_status = 2 : this.ruleForm.work_status = 1
						if(this.ruleForm.id == 0){
							this.$http.post("admin/member/create", this.ruleForm).then(res => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						}else {
							this.$http.post("admin/member/edit", this.ruleForm).then(res => {
								if(res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '修改成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						}
						

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/member/driverlist", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
		}
	}
</script>

<style>

</style>
