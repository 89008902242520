import { render, staticRenderFns } from "./AddGoods.vue?vue&type=template&id=9fcc5cc2&scoped=true&"
import script from "./AddGoods.vue?vue&type=script&lang=js&"
export * from "./AddGoods.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./AddGoods.vue?vue&type=style&index=1&id=9fcc5cc2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fcc5cc2",
  null
  
)

export default component.exports