<template>
	<div class="login-container">
		<div class="login-box">
			<!-- 头像区 -->
			<div class="avatar-box">
				<img src="https://xindian.oss-cn-beijing.aliyuncs.com/covers/172598f1410cef7354e1341a5133eecc.jpg"
					alt="" />
			</div>
			<!-- 登录表单区 -->
			<el-form :model="loginForm" :rules="loginFormRules" label-width="0px" class="loginForm" ref="loginFormRef">
				<!-- 用户名 -->
				<el-form-item prop="username">
					<el-input v-model="loginForm.username" prefix-icon="iconfont iconyonghu"></el-input>
				</el-form-item>
				<!-- 密码 -->
				<el-form-item prop="password">
					<el-input v-model="loginForm.password" prefix-icon="iconfont iconmimabeifen" type="password">
					</el-input>
				</el-form-item>
				<!-- 验证码 -->
				<div class="code">
					<div>
						<el-form-item>
							<el-input v-model="loginForm.code" prefix-icon="" type="text">
							</el-input>
						</el-form-item>
					</div>
					<div @click="getCodeImg">
						<img :src="'http://ljerp.csdu.net' + codeDate.img_url" alt="" />
					</div>
				</div>
				<!-- 按钮区域 -->
				<el-form-item class="btns">
					<el-button type="primary" @keyup.enter="login" @click="login">登录</el-button>
					<el-button type="info" @click="resetLoginForm('loginFormRef')">重置</el-button>
					<!-- <el-button type="info" @click="printPreview(1)">打印测试</el-button> -->
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/assets/js/LodopFuncs'
	export default {
		data() {
			return {
				// 验证码数据
				codeDate: {},
				// 登录表单的数据绑定对象
				loginForm: {
					username: "",
					password: "",
					code: "",
				},
				//   表单验证规则
				loginFormRules: {
					//   验证用户名是否合法
					username: [{
							required: true,
							message: "请输入用户名",
							trigger: "blur",
						},
						{
							min: 3,
							max: 20,
							message: "用户名长度在3到10之间",
							trigger: "blur",
						},
					],
					//   验证密码是否合法
					password: [{
							required: true,
							message: "请输入密码",
							trigger: "blur",
						},
						{
							min: 6,
							max: 15,
							message: "密码长度在大于6到15之间",
							trigger: "blur",
						},
					],
				},
			};
		},

		created() {
			this.getCodeImg();
		},

		methods: {

			printPreview(s) {
				this.CreateOneFormPage()
				if (s == 0) {
					LODOP.PRINT() //直接打印
				}
				if (s == 1) {
					LODOP.PREVIEW() //打印预览
				}
				if (s == 2) {
					LODOP.PRINT_SETUP() //打印维护
				}
				if (s == 3) {
					LODOP.PRINT_DESIGN() //打印设计
				}
			},
			CreateOneFormPage() {
				LODOP = getLodop()
				//样式
				var olstyle1 = '<style>' + document.getElementById('olstyle1') + '</style>'
				var body = olstyle1 + '<body>' + document.getElementById('form1').innerHTML + '</body>'
				LODOP.PRINT_INIT('订单') //打印初始化
				LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
				LODOP.SET_PRINT_STYLE('Bold', 1) //设置对象风格
				LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
				LODOP.SET_PRINT_PAGESIZE(0, 2000, 2000, '') //设定纸张大小
				LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%') //设置缩放
				LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '') //设置窗口
				// 打印二维码
				// LODOP.ADD_PRINT_BARCODE(23,23,233,233,"QRCode","https://blog.csdn.net/qq_43652509");
				//打印网址
				// LODOP.ADD_PRINT_URL(222,2000,2000,233,"https://blog.csdn.net/qq_43652509");
				//打印图片
				// LODOP.ADD_PRINT_IMAGE(100,100,400,400,"<img border='0' src='http://s1.sinaimg.cn/middle/4fe4ba17hb5afe2caa990&690' width='345' height='250'>");
				LODOP.ADD_PRINT_HTM(88, 20, 2000, 2000, body) //增加超文本项
			},

			//	获取头像验证码
			getCodeImg() {
				this.$http.get("captcha/").then((res) => {
					this.codeDate = res.data.data;
				});
			},
			//   点击重置按钮 重置表单
			resetLoginForm(formName) {
				this.$refs[formName].resetFields();
			},
			login() {
				this.$refs.loginFormRef.validate(async (valid) => {
					if (!valid) {
						return;
					}
					let data = {
						user_name: this.loginForm.username,
						pass: this.loginForm.password,
						captcha_value: this.loginForm.code,
						captcha_id: this.codeDate.id,
					};
					this.$http.post("admin/users/login", data).then((res) => {
						if (res.data.code !== 200) {
							return this.$message.error("登陆失败");
						} else {
							this.$message.success("登录成功");
							sessionStorage.setItem("token", "Bearer " + res.data.data.token);
							this.$router.push("/");
							sessionStorage.setItem(
								"editableTabs",
								JSON.stringify([{
									title: "动态首页",
									name: "1",
									content: "HomeView",
									id: 53
								}, ])
							);
						}
					}).catch((err) => {
						return this.$message.error(err.response.data.msg);
					});
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.login-container {
		background: #3d80a7;
		height: 100%;
	}

	.login-box {
		width: 450px;
		height: 350px;
		background-color: #fff;
		border-radius: 3px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.avatar-box {
			height: 130px;
			width: 130px;
			border-radius: 50%;
			overflow: hidden;
			box-shadow: 0 0 0 5px #fff;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.loginForm {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 0 20px;
			box-sizing: border-box;
		}

		.btns {
			display: flex;
			justify-content: flex-end;
		}
	}

	.code {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
