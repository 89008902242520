<template>
	<div style="padding: 20px;">
		<el-dialog :visible.sync="dialogVisible3" width="50%" :before-close="handleClose3">
			<el-form label-position="top" label-width="100px" class="demo-ruleForm">
	<!-- 			<el-form-item label="仓库">
					<el-input :value="orderDetail.warehouse_info.name" disabled></el-input>
				</el-form-item> -->
				<el-table :data="orderDetail.detail">
					<el-table-column prop="goods_name" label="商品名称"></el-table-column>
					<el-table-column prop="goods_num" label="商品数量"></el-table-column>
					<el-table-column prop="goods_price" label="商品单价"></el-table-column>
					<el-table-column prop="unit2_unit1_name" label="单位换算"></el-table-column>
				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="handleClose3">关闭</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		

		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="created_at" label="创建日期">
				</el-table-column>
				<el-table-column prop="order_no" label="订单号">
				</el-table-column>
				<el-table-column prop="customer_info.name" label="客户">
				</el-table-column>
				<el-table-column prop="salesman_name" label="业务员">
				</el-table-column>
				<el-table-column prop="warehouse_info.name" label="仓库">
				</el-table-column>
				<el-table-column prop="money" label="订单金额">
				</el-table-column>
				<el-table-column label="付款状态">
					<template slot-scope="scope">
						{{scope.row.pay_type === 1 ? '现结' : '挂账'}}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="openDetail(scope.row.order_no)" type="text" size="small">查看详情</el-button>
						<el-button @click="printView(scope.row.id)" type="text" size="small">打印预览</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				input: "",
				title: "",
				dialogVisible: false,
				dialogVisible3: false,
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
				warehouse: [],
				members: [],
				goods: [],
				orderDetail: {
					warehouse_info: {
						name: ""
					},
					deatil: []
				},
				template: null,
			}
		},

		mounted() {
			this.getData()
			this.getTemplate()
		},

		methods: {
			getTemplate() {
			    this.$http.get("admin/report/templatelistbyid", {
			        params: {
			            id: 3
			        }
			    }).then(res => {
			        this.template = res.data.data.template
			    })
			},
			
			printView(order_no) {
			    this.$http.get("/admin/print/car", {
			        params: {
			            order_id: order_no
			        }
			    }).then(res => {
			        this.$lodop.preview(JSON.parse(this.template), res.data.data);
			    })
			},
			
			handleClose3() {
				this.dialogVisible3 = false
			},
			
			handleClose() {
				this.dialogVisible = false
				this.resetForm("ruleForm")
			},
			
			openDetail(order_no) {
				this.$http.get("admin/order/detail", {
					params: {order_no, type: 5}
				}).then((res) => {
					this.orderDetail = res.data.data.data
					this.dialogVisible3 = true
				});
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post("admin/brand/create", this.ruleForm).then(res => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm")
								this.getData()
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.dialogVisible = false
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/order/carlist", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
		}
	}
</script>

<style>

</style>
