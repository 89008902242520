<template>
	<div style="padding: 20px;">
		<!-- 详情 -->
		<el-dialog :visible.sync="dialogVisible5" width="50%" :before-close="handleClose5">
			<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
			<!-- 	<el-form-item label="仓库">
					<el-input :value="orderDetail.warehouse_info.name" disabled></el-input>
				</el-form-item> -->
				<el-table :data="orderDetail.detail">
					<el-table-column prop="goods_name" label="商品名称"></el-table-column>
					<el-table-column prop="goods_num" label="商品数量"></el-table-column>
					<el-table-column prop="goods_price" label="商品单价"></el-table-column>
					<el-table-column prop="unit2_unit1_name" label="单位换算"></el-table-column>
				</el-table>
				<el-form-item style="margin-top: 20px;">
					<el-button type="primary" @click="handleClose5">关闭</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<el-dialog :visible.sync="dialogVisible2" width="50%" :before-close="handleClose2">
			<el-table :data="tableData4" border ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="name" label="商品名称"></el-table-column>
				<el-table-column prop="code" label="商品编码"></el-table-column>
				<el-table-column prop="stock.quantity" label="大单位库存"></el-table-column>
				<el-table-column prop="stock.quantity_little" label="小单位库存"></el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange4" @current-change="handleCurrentChange4"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total4">
			</el-pagination>
			<el-button type="primary" @click="pushMList">确定</el-button>
		</el-dialog>
		
		<el-dialog :visible.sync="dialogVisible3" width="50%" :before-close="handleClose3">
			<el-table :data="tableData3" highlight-current-row @current-change="handleSelectionChange3">
				<el-table-column type="index" width="55"></el-table-column>
				<el-table-column prop="name" label="客户名称"></el-table-column>
				<el-table-column prop="boss_mobile" label="客户电话"></el-table-column>
				<el-table-column prop="address" label="客户地址"></el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total3">
			</el-pagination>
			<el-button type="primary" @click="pushMList3">确定</el-button>
		</el-dialog>

		<el-dialog title="创建订单" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
			<el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="客户" prop="costomer_id">
					<div @click="openCostomer">
						<el-input v-model="costomer_name" placeholder="请选择客户"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="仓库" prop="warehouse_id">
					<el-select v-model="ruleForm.warehouse_id">
						<el-option v-for="item in warehouse" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="业务员" prop="salesman_id">
					<el-select v-model="ruleForm.salesman_id" @change="optionSales">
						<el-option v-for="item in members" :key="item.id" :label="item.user_name" :value="item.id + ',' + item.user_name">
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="总价" prop="money">
					<el-input type="number" v-model.number="ruleForm.money" placeholder="请输入总价"></el-input>
				</el-form-item>
				
	<!-- 			<el-form-item label="司机" prop="driver_id">
					<el-select v-model="ruleForm.driver_id">
						<el-option
						 v-for="item in driverlist" :key="item.id" :label="item.user_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item> -->
				<div style="padding: 0 50px;">
					<el-table :data="multipleSelection" style="margin-bottom: 30px;">
						<el-table-column prop="name" label="商品名称"></el-table-column>
						<el-table-column label="选择单位">
							<template slot-scope="scope">
								<el-select v-model="multipleSelection[scope.$index].unit" placeholder="请选择">
									<template v-for="item in multipleSelection[scope.$index].sku">
										<el-option :label="item.name" :value="item.unit_id + ',' + item.unit_type">
										</el-option>
									</template>
								</el-select>
							</template>
						</el-table-column>
						<el-table-column label="入库数量">
							<template slot-scope="scope">
								<el-input-number v-model="multipleSelection[scope.$index].goods_num" :min="1" label="描述文字">
								</el-input-number>
							</template>
						</el-table-column>
					</el-table>
					<el-form-item style="margin-top: 20px;">
						<el-button type="primary" @click="openGoods">导入商品</el-button>
						<el-button type="primary" @click="submitForm2('ruleForm')">提交</el-button>
						<el-button @click="resetForm2('ruleForm')">重置</el-button>
					</el-form-item>
				</div>

			</el-form>
		</el-dialog>
		

		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="openForm">创建订单</el-button>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="created_at" label="创建时间">
				</el-table-column>
				<el-table-column prop="salesman_name" label="业务员">
				</el-table-column>
				<el-table-column prop="money" label="金额">
				</el-table-column>
				<el-table-column prop="order_no" label="订单号">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="openDetail(scope.row.order_no)" type="text" size="small">查看详情</el-button>
						<el-button @click="printView(scope.row.id)" type="text" size="small">打印预览</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				costomer_name: "",
				multipleSelection: [],
				
				ruleForm: {
					costomer_id: null,
					warehouse_id: null,
					salesman_id: null,
					salesman_name: '',
					money: null,
					// driver_id: null,
				},
				rules: {
					costomer_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					warehouse_id: [{
						required: true,
						message: '请选择仓库',
						trigger: 'blur'
					}],
					salesman_id: [{
						required: true,
						message: '请选择业务员',
						trigger: 'blur'
					}],
					money: [{
						required: true,
						message: '请输入金额',
						trigger: 'blur'
					}],
					// driver_id:  [{
					// 	required: true,
					// 	message: '请输入金额',
					// 	trigger: 'blur'
					// }],
				},
				dialogVisible: false,
				dialogVisible2: false,
				dialogVisible3: false,
				dialogVisible5: false,
				total: 0,
				total2: 0,
				total3: 0,
				total4: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				pageInfo2: {
					page: 1,
					limit: 100
				},
				pageInfo3: {
					page: 1,
					limit: 10
				},
				pageInfo4: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
				tableData2: [],
				tableData3: [],
				tableData4: [],
				warehouse: [],
				members: [],
				driverlist: [],
				goods: [],
				orderDetail: {},
				template: null,
			}
		},

		mounted() {
			this.getData()
			this.getTemplate()
			this.getInfo()
		},

		methods: {
			
			
			submitForm2(formName) {
				if (this.multipleSelection.length <= 0) {
					this.$message({
						message: "请选择商品",
						type: "warning",
					});
					return
				}
			
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let detail = []
						
						this.multipleSelection.forEach(item => {
							try{
								let units = item.unit.split(",")
								detail.push({
									"goods_id": item.id, 
									"unit_id": parseInt(units[0]), 
									"unit_type": parseInt(units[1]), 
									"goods_num": item.goods_num
								})
							}catch(e){
								this.$message({
									message: "请选择单位，填写数量",
									type: "success",
								});
								return
							}
						})
						let tempSalesman = this.ruleForm.salesman_id
						this.ruleForm.salesman_id = parseInt(tempSalesman.split(",")[0])
						this.ruleForm.salesman_name = tempSalesman.split(",")[1]
						
						this.ruleForm.detail = detail
						this.$http.post("admin/order/saleback", this.ruleForm).then((res) => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm");
								this.$message({
									message: "创建成功",
									type: "success",
								});
								this.dialogVisible = false;
								this.getData()
							}else{
								this.$message({
									message: res.data.msg,
									type: "error",
								});
							}
						}).catch((err) => {
							this.$message({
								message: err.response.data.msg,
								type: "error",
							});
						});
					} else {
						this.$message({
							message: "创建失败",
							type: "error",
						});
						return false;
					}
				});
			},
			
			optionSales(val) {
				console.log("这里")
				console.log(val)
			},
			
			getInfo() {
				this.$http.get("admin/warehouse/list", {
					params: this.pageInfo2
				}).then(res => {
					this.warehouse = res.data.data.data
				})
				
				this.$http.get("admin/member/list", {
					params: this.pageInfo2
				}).then(res => {
					this.members = res.data.data.data
				})
				this.$http.get("admin/member/driverlist", {
					params: this.pageInfo2
				}).then(res => {
					this.driverlist = res.data.data.data
				})
				
				this.getClient()
			},
			
			getClient() {
				this.$http.get("admin/client/list", {
					params: this.pageInfo3
				}).then(res => {
					this.tableData3 = res.data.data.data
					this.total3 = res.data.data.count
				})
			},
			
			pushMList() {
				this.dialogVisible2 = false
			},
			
			pushMList3() {
				this.dialogVisible3 = false
			},
			
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			
			handleSelectionChange3(val) {
				this.ruleForm.costomer_id = val.id
				this.costomer_name = val.name
			},
			
			handleClose2() {
				this.multipleSelection = []
				this.ruleForm.out_warehouse_id = 0
				this.dialogVisible2 = false
			},
			
			handleClose3() {
				this.multipleSelection3 = []
				this.dialogVisible3 = false
			},
			
			getData2() {
				this.$http.get("admin/goods/list", {
						params: this.pageInfo4,
				}).then((res) => {
					this.tableData4 = res.data.data.data;
					this.total4 = res.data.data.count;
					this.dialogVisible2 = true
				});
			},
			
			openGoods() {
				this.getData2()
			},
			
			openCostomer() {
				this.dialogVisible3 = true
			},
			
			handleCurrentChange2(val) {
				this.pageInfo2.page = val
				this.getData2()
			},
			
			handleCurrentChange3(val) {
				this.pageInfo3.page = val
			},
			
			handleSizeChange2(val) {
				this.pageInfo2.limit = val
				this.getData2()
			},
			
			handleSizeChange4(val) {
				this.pageInfo4.limit = val
				this.getData2()
			},
			
			handleSizeChange3(val) {
				this.pageInfo2.limit = val
			},
			
			handleSizeChange4(val) {
				this.pageInfo4.limit = val
			},
			
			openForm() {
				this.dialogVisible = true
			},

			printView(order_no) {
				this.$http.get("/admin/print/visitback", {
					params: {
						order_id: order_no
					}
				}).then(res => {
					this.$lodop.preview(JSON.parse(this.template), res.data.data);
				})
			},

			getTemplate() {
				this.$http.get("admin/report/templatelistbyid", {
					params: {
						id: 4
					}
				}).then(res => {
					this.template = res.data.data.template
				})
			},

			openDetail(order_no) {
				this.$http.get("admin/order/detail", {
					params: {
						order_no,
						type: 6
					}
				}).then((res) => {
					this.orderDetail = res.data.data.data
					this.dialogVisible5 = true
				});
			},
			
			handleClose5() {
				this.dialogVisible5 = false
			},

			handleClose() {
				this.dialogVisible = false
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post("admin/brand/create", this.ruleForm).then(res => {
							if (res.data.code == 200) {
								this.resetForm("ruleForm")
								this.getData()
								this.$message({
									message: '添加成功',
									type: 'success'
								});
								this.dialogVisible = false
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},
			
			handleCurrentChange4(val) {
				this.pageInfo4.page = val
				this.getData2()
			},

			getData() {
				this.$http.get("admin/order/visitbacklist", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
		}
	}
</script>

<style>
</style>
