<template>
	<div style="padding: 20px;">
		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="name" label="商品名">
				</el-table-column>
				<el-table-column prop="Category.name" label="商品分类">
				</el-table-column>
				<el-table-column prop="brand.name" label="商品品牌">
				</el-table-column>
				<el-table-column prop="quantity" label="大单位库存">
				</el-table-column>
				<el-table-column prop="quantity_little" label="小单位库存">
				</el-table-column>
				<el-table-column prop="warehouse_info.real_name" label="业务员">
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				currentPage: 1,
				tableData: [],
			}
		},

		mounted() {
			this.getData()
		},

		methods: {
			getData() {
				this.$http.get("admin/stockpile/saleinfo", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
		}
	}
</script>

<style>
</style>
