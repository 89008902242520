
<template>
  <div>
	<el-card class="box-card" style="height: 100%">
	  <div class="personal">
	    <div>
	      <el-avatar :size="50" :src="circleUrl" />
	    </div>
	    <div class="name"></div>
	    <div class="description"></div>
	    <div class="list">
	      <div>昵称：{{$store.state.userInfo.real_name}}</div>
	      <div>年龄：~~</div>
	      <div>性别：男</div>
	      <div>手机：1135957121</div>
	    </div>
	    <el-divider></el-divider>
	    <div style="margin-bottom: 15px"><h5>快捷导航</h5></div>
	    <div>
	      <el-tag style="margin-right: 10px">怕麻烦</el-tag>
	      <el-tag style="margin-right: 10px">健身运动</el-tag>
	      <el-tag style="margin-right: 10px">睡觉</el-tag>
	      <el-tag style="margin-right: 10px">漫威</el-tag>
	      <el-tag>向往</el-tag>
	    </div>
	  </div>
	</el-card>
  </div>
</template>
<script>
	export default {
		components: {},
		data() {
			return {
				circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
			}
		},
		methods: {
			goTo(url) {
				window.open(url, '_blank')
			},
		}
	}
</script>

<style scoped lang="less">
	.personal {
		.name {
			margin-top: 15px;
			font-size: 24px;
			font-weight: 500;
			color: rgb(38, 38, 38);
		}

		.description {
			margin-top: 8px;
		}

		.list {
			margin-top: 18px;
			line-height: 30px;
			text-align: left;
		}
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text {
		font-size: 14px;
	}

	.grid-content {
		display: flex;

		.left {
			width: 40%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #2d8cf0;
		}

		.right {
			display: flex;
			flex-direction: column;
			padding-left: 20px;
			justify-content: center;
		}
	}

	.custom {
		::v-deep {
			.el-card__body {
				padding: 0;
			}
		}
	}

	.item {
		margin-bottom: 18px;
	}

	.box-card {
		//height: 100%;
		margin-bottom: 10px;
		width: 100%;
	}

	.card-item {

		background: linear-gradient(50deg, #1890ff, #77e19d);
	}
</style>
