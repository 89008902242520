<template>
	<div style="padding: 20px;">
		<el-dialog :title="ruleForm.id == 0 ? '添加用户': '修改用户'" :visible.sync="dialogVisible" width="30%"
			:before-close="handleClose">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="用户名" prop="user_name">
					<el-input v-model="ruleForm.user_name"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名" prop="real_name">
					<el-input v-model="ruleForm.real_name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="ruleForm.remark"></el-input>
				</el-form-item>
				<el-form-item label="区域" prop="area_id">
					<el-select v-model="ruleForm.area_id">
						<el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="头像" prop="avatar">
					<el-upload class="avatar-uploader" action="https://app.api.ezagooad.com/lsm/api/upload/ossfiles"
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-card shadow="always" style="margin-bottom: 20px;">
			<el-row :gutter="20">
				<el-col :span="2">
					<el-button type="primary" @click="dialogVisible = true">添加用户</el-button>
				</el-col>
			</el-row>
		</el-card>
		<el-card shadow="always">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="created_at" label="创建日期">
				</el-table-column>
				<el-table-column prop="user_name" label="用户名称">
				</el-table-column>
				<el-table-column prop="real_name" label="真实姓名">
				</el-table-column>
				<el-table-column prop="company" label="公司">
				</el-table-column>
				<el-table-column prop="phone" label="电话号码">
				</el-table-column>
				<el-table-column prop="area_name" label="区域">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" type="text" size="small">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="1" :page-sizes="[10, 20, 30, 100]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				fileList: [],

				ruleForm: {
					id: 0,
					user_name: "",
					real_name: "",
					phone: "",
					remark: "",
					avatar: "",
					user_type: 1,
					area_id: null,
				},

				rules: {
					user_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					remark: [{
						required: true,
						message: '请输入备注',
						trigger: 'blur'
					}],
					avatar: [{
						required: true,
						message: '请输上传头像',
						trigger: 'blur'
					}],
					area_id: [{
						required: true,
						message: '请选择区域',
						trigger: 'blur'
					}],
				},

				input: "",
				title: "",
				dialogVisible: false,
				total: 0,
				pageInfo: {
					page: 1,
					limit: 10
				},
				pageInfo2: {
					page: 1,
					limit: 1000
				},
				currentPage: 1,
				tableData: [],
				warehouse: [],
				members: [],
				goods: [],
				areaList: [],
			}
		},

		mounted() {
			this.getData()
			this.getData2()
		},

		methods: {
			editRow(row) {
				this.$http.get("admin/member/listbyid", {
					params: {id: row.id}
				}).then(res => {
					this.ruleForm = res.data.data
					this.dialogVisible = true
				})
			},
			
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data
			},

			handleClose() {
				this.resetForm("ruleForm")
				this.dialogVisible = false
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.ruleForm.id == 0){
							this.$http.post("admin/member/create", this.ruleForm).then(res => {
								if (res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						}else {
							this.$http.post("admin/member/edit", this.ruleForm).then(res => {
								if(res.data.code == 200) {
									this.resetForm("ruleForm")
									this.getData()
									this.$message({
										message: '修改成功',
										type: 'success'
									});
									this.dialogVisible = false
								}
							})
						}


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleSizeChange(val) {
				this.pageInfo.limit = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.pageInfo.page = val
				this.getData()
			},

			getData() {
				this.$http.get("admin/member/list", {
					params: this.pageInfo
				}).then(res => {
					this.tableData = res.data.data.data
					this.total = res.data.data.count
				})
			},
			
			getData2() {
				this.$http.get("admin/area/list", {
					params: this.pageInfo2
				}).then(res => {
					this.areaList = res.data.data.data
				})
			},
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px!important;
		text-align: center;
		
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
