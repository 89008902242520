<template>
  <div style="height: 100vh; overflow-y: scroll;">
    <div style="display: flex;justify-content: space-between;letter-spacing: 2px; font-size: 18px;color: #ffffffb2;line-height: 50px;width: 100%;padding:0 20px;box-sizing: border-box;">
      <span v-if="!isCollapse">郎酒管理系统</span>
      <div>
        <i v-if="!isCollapse" class="el-icon-s-fold" @click="changMenu"></i>
        <i v-else class="el-icon-s-unfold" @click="changMenu"></i>
      </div>
    </div>
    <el-menu
      :default-active="$store.state.active"
      class="el-menu-vertical-demo"
      background-color="#191a23"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
      :collapse="isCollapse"
      style="border-right: 0; letter-spacing: 3px"
      :collapse-transition="true"
    >
      <template v-for="item in $store.state.userInfo.menus">
        <el-menu-item
          :index="item.path"
          :key="item.path"
          v-if="!item.children.length"
          @click="saveNavState(item)"
        >
          <i :class="item.icon"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
        <el-submenu :index="item.path" :key="item.path" v-else>
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </template>
          <el-menu-item-group v-for="info in item.children">
            <el-menu-item :index="info.path" @click="saveNavState(info)">
              <i :class="info.icon"></i>
              <span slot="title">{{ info.title }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: ["isCollapse"],
  methods: {
    saveNavState(item) {
      this.$EventBus.$emit("aMenu", item);
    },

    changMenu() {
      this.$emit("changeShow");
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  border-right: 0;
  /deep/ .is-active {
    background: #2d8cf0 !important;
    color: #fff !important;
  }

  /deep/ .el-submenu__title {
    font-size: 15px !important;
    color: #ffffffb2 !important;

    i {
      color: #ffffffb2 !important;
    }
  }
}

.el-menu-item {
  color: #ffffffb2 !important;
  i {
    color: #ffffffb2 !important;
  }
}
.el-menu-vertical-demo {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei;
  font-size: 16px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 256px;
  min-height: 400px;
}
</style>
