<template>
  <div>
    <el-menu
      :default-active="activeIndex1"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="basicMsg">基本信息</el-menu-item>
      <el-menu-item index="priceManag">售价管理</el-menu-item>
      <el-menu-item index="imgDetails">图文详情</el-menu-item>
    </el-menu>

    <!-- 基本信息 -->
    <div class="basicMsg" v-if="componentsName === 'basicMsg'">
      <el-form
        class="basic-form"
        label-width="120px"
        :rules="rules"
        :model="basicForm"
        ref="basicForm"
      >
        <el-form-item label="商品名称" prop="name">
          <el-input placeholder="请输入" v-model="basicForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商品助记名" prop="zjm">
          <el-input placeholder="请输入" v-model="basicForm.zjm"></el-input>
        </el-form-item>
        <el-form-item label="商品类别" prop="cid">
          <el-cascader
            style="width: 350px"
            v-model="value"
            :options="categoryOptions"
            :props="{
              expandTrigger: 'hover',
              value: 'id',
              label: 'name',
              children: 'Children',
              checkStrictly: true,
            }"
            @change="handleChange"
          >
          </el-cascader>
          <!-- 		<el-select v-model="basicForm.cid">
					
					<el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select> -->
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input placeholder="请输入" v-model="basicForm.spec"></el-input>
        </el-form-item>
        <el-form-item label="商品编号" prop="code">
          <el-input placeholder="请输入" v-model="basicForm.code"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="basicForm.status">
            <el-option
              v-for="item in statusOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原产地" prop="place">
          <el-input placeholder="请输入" v-model="basicForm.place"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand_id">
          <el-select v-model="basicForm.brand_id">
            <el-option
              v-for="item in brandOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="1">单位信息</el-menu-item>
      </el-menu>
      <div class="cards">
        <el-card>
          <div style="text-align: center">小单位</div>
          <el-form
            label-width="100px"
            :rules="rules1"
            :model="unitFormS"
            ref="unitFormS"
            style="margin-top: 20px"
          >
            <el-form-item label="小单位类型">
              <el-select
                v-model="unitFormS.bid"
                value-key="value"
                @change="changeS"
              >
                <el-option
                  v-for="item in unitLists1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="barCode" label="小单位条码">
              <el-input
                v-model="unitFormS.barCode"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <div style="text-align: center">大单位</div>
          <el-form
            label-width="100px"
            :model="unitForm"
            ref="unitForm"
            :rules="rules2"
            style="margin-top: 20px"
          >
            <el-form-item label="大单位">
              <el-select
                v-model="unitForm.bid"
                value-key="value"
                @change="changeB"
              >
                <el-option
                  v-for="item in unitLists2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="barCode" label="大单位条码">
              <el-input
                v-model="unitForm.barCode"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item prop="conversion" label="大单位换算">
              <el-input
                :disabled="!unitFormS.bid? true : false"
                v-model="unitForm.conversion"
                placeholder="=请输入"
              >
                <template slot="append" v-if="unitFormS.bid">{{
                  computedS
                }}</template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 售价管理 -->
    <div class="priceManag" v-else-if="componentsName === 'priceManag'">
      <el-card style="width: 100%; box-sizing: border-box">
        <el-table
          :data="priceManagData"
          style="width: 100%; box-sizing: border-box"
          :border="true"
          :header-cell-style="{ background: '#f3f3f3' }"
        >
          <el-table-column prop="name" label="单位" width="120" align="center">
          </el-table-column>
          <el-table-column
            prop="wholesalePrice"
            label="批发价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                class="item"
                v-model="scope.row.wholesalePrice"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="retailPrice"
            label="零售价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input class="item" v-model="scope.row.retailPrice"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="minimumPrice"
            label="最低零售价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                class="item"
                v-model="scope.row.minimumPrice"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="purchasePrice"
            label="进价(元)"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                class="item"
                v-model="scope.row.purchasePrice"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column></el-table-column>
        </el-table>
      </el-card>
    </div>

    <!-- 图片详情 -->
    <div class="imgDetails" v-else>
      <!-- 顶部图片 -->
      <div
        style="
          display: flex;
          margin-top: 20px;
          padding-left: 20px;
          box-sizing: border-box;
        "
      >
        <!-- 商品图片 -->
        <div style="margin-right: 50px; width: 600px">
          <div style="line-height: 40px; margin-left: 10px"><span style="color: red;margin-right: 3px;font-size: 14px;">*</span>商品图片</div>
          <div style="position: relative; width: fit-content">
            <div
              v-if="!imgList[0]"
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                text-align: center;
                background-color: #0e4183;
                color: white;
                border-radius: 0 0 6px 6px;
              "
            >
              点击上传图片
            </div>
            <el-upload
              action="https://app.api.ezagooad.com/lsm/api/upload/ossfiles"
              :limit="3"
              ref="upload"
              :on-success="handleAvatarSuccess"
              list-type="picture-card"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
              </div>
            </el-upload>
          </div>
        </div>

        <!-- 封面 -->
        <div>
          <div style="line-height: 40px"><span style="color: red;margin-right: 3px;font-size: 14px;">*</span>封面</div>
          <div style="position: relative">
            <div
              v-if="!cover"
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                text-align: center;
                background-color: #0e4183;
                color: white;
                border-radius: 0 0 6px 6px;
              "
            >
              点击上传封面
            </div>
            <el-upload
              action="https://app.api.ezagooad.com/lsm/api/upload/ossfiles"
              list-type="picture-card"
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
            >
              <img v-if="cover" :src="cover" class="avatar" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </div>

      <!-- 商品详情 -->
      <div style="margin: 20px; margin-top: 50px; box-sizing: border-box">
        <div style="line-height: 40px; margin-left: 10px"><span style="color: red;margin-right: 3px;font-size: 14px;">*</span>商品详情</div>
        <el-card>
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 300px; overflow-y: hidden"
            v-model="goodsDetail"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </el-card>
      </div>
    </div>

    <!-- 下方按钮区 -->
    <div class="btns">
      <el-button
        v-if="!(componentsName === 'basicMsg')"
        type="primary"
        @click="back()"
        >上一步</el-button
      >
      <el-button type="primary" @click="submit()">{{
        this.componentsName === "imgDetails" ? "提交" : "下一步"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "addGoods",
  components: {
    Editor,
    Toolbar,
  },

  // 计算出单位名
  computed: {
    computedS() {
      let temp;
      this.unitLists1.forEach((itm) => {
        if (itm.id === this.unitFormS.bid) {
          temp = itm.name;
        }
      });
      return temp;
    },
  },
  data() {
    return {
      goodsInfo: {},
      flag: 0,
	  id: 0,
      componentsName: "basicMsg",
      activeIndex1: "basicMsg",
      activeIndex: "1",
      basicForm: {
        name: "",
        // brand: "",
        zjm: "",
        cid: null,
        spec: "",
        code: "",
        status: "",
        place: "",
		brand_id: null,
      },
      unitFormS: {
        name: "",
        bid: null,
        barCode: "",
        wholesalePrice: 0,
        retailPrice: 0,
        minimumPrice: 0,
        purchasePrice: 0,
      },
      unitForm: {
        name: "",
        bid: null,
        barCode: "",
        conversion: null,
        wholesalePrice: 0,
        retailPrice: 0,
        minimumPrice: 0,
        purchasePrice: 0,
      },
      rules1: {
        // bid: [
        //   {
        //     required: true,
        //     message: "请选择小单位类型",
        //     trigger: "blur",
        //   },
        // ],
      }, 
      rules2: {
        // bid: [
        //   {
        //     required: true,
        //     message: "请选择大单位类型",
        //     trigger: "blur",
        //   },
        // ],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
        brand_id: [{
        	required: true,
        	message: "请选择商品品牌",
        	trigger: "blur"
        }],
        zjm: [
          {
            required: true,
            message: "请输入商品助记名",
            trigger: "blur",
          },
        ],
        cid: [
          {
            required: true,
            message: "请选择商品类别",
            trigger: "blur",
          },
        ],
      },
      brandOptions: [
        {
          name: "小米",
          id: 1,
        },
        {
          name: "华为",
          id: 2,
        },
      ],
      categoryOptions: [],
      statusOptions: [
        {
          name: "正常",
          id: 1,
        },
        {
          name: "停用",
          id: 2,
        },
      ],
      unitLists1: [],
      unitLists2: [],

      pageInfo1: {
        page: 1,
        limit: 10000,
        unit_type: 1,
      },

      pageInfo2: {
        page: 1,
        limit: 10000,
        unit_type: 2,
      },
      value: [],

      // 售价管理的数据
      priceManagData: [],

      // 图文详情的数据
      cover: "",
      imgList: [],
      goodsDetail: "",
      // 富文本数据
      editor: null,
      toolbarConfig: {
        excludeKeys: ["fullScreen"],
      },
      editorConfig: {
        placeholder: "请输入内容...",

        //看这里
        //-----------------------------------
        MENU_CONF: {
          uploadImage: {
            //server必须要配置正确
            server: "https://app.api.ezagooad.com/lsm/api/upload/ossfiles",

            maxFileSize: 20 * 1024 * 1024, // 1M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: [],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            fieldName: "file",
            customInsert(res, insertFn) {
              // JS 语法
              // res 即服务端的返回结果
              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(res.data);
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },

  mounted() {
    this.getCate();
    this.getUnit1();
    this.getUnit2();
	this.getBanner();
  },

  // computed: {
  //   ...mapState(["goodsInfo"]),
  // },

  methods: {
	getData() {
		
	},
	  
	getBanner() {
		this.$http
		  .get("admin/brand/list", {
		    params: this.pageInfo1,
		  })
		  .then((res) => {
		    this.brandOptions = res.data.data.data;
		  });
	},
	  
    // 获取单位名字
    changeS(s) {
      this.unitLists1.forEach((itm) => {
        if (itm.id === s) {
          this.unitFormS.name = itm.name;
        }
      });
    },
    changeB(b) {
      this.unitLists2.forEach((itm) => {
        if (itm.id === b) {
          this.unitForm.name = itm.name;
        }
      });
    },
    back() {
      if (this.componentsName === "priceManag") {
        this.activeIndex1 = this.componentsName = "basicMsg";
      } else {
        this.activeIndex1 = this.componentsName = "priceManag";
      }
    },

    handleSelect(index) {
      this.componentsName = index;
    },

    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(editor.getMenuConfig("uploadImage"));
    },
    handleAvatarSuccess(res, file) {
      this.imgList.push(res.data);

      const uploadDom = this.$refs["upload"];
      if (this.imgList.length === 3) {
        uploadDom.$children[1].$el.style.display = "none";
      } else {
        uploadDom.$children[1].$el.style.display = "";
      }
    },
    handleAvatarSuccess1(res, file) {
      this.cover = res.data;
    },

    handleChange(value) {
      this.value = value;
      this.basicForm.cid = value[value.length - 1];
    },
    getUnit1() {
      this.$http
        .get("admin/unit/list", {
          params: this.pageInfo1,
        })
        .then((res) => {
          this.unitLists1 = res.data.data.data;
        });
    },

    getUnit2() {
      this.$http
        .get("admin/unit/list", {
          params: this.pageInfo2,
        })
        .then((res) => {
          this.unitLists2 = res.data.data.data;
        });
    },

    getCate() {
      this.$http.get("admin/cate/list").then((res) => {
        this.categoryOptions = res.data.data;
      });
    },
    submit() {
      switch (this.componentsName) {
        case "basicMsg":
          this.saveBasic();
          break;
        case "priceManag":
          this.savePrice();
          break;
        case "imgDetails":
          this.submitAll();
          break;
      }

      // this.$refs.unitFormS.validate(async (valid) => {
      // 	if (!valid) {
      // 		return
      // 	}
      // 	this.$refs.unitForm.validate(async (valid2) => {
      // 		if (!valid2) {
      // 			return
      // 		}
      // 		this.$refs.basicForm.validate(async (valid3) => {
      // 			if (!valid3) {
      // 				return
      // 			}

      // 			let data = []
      // 			data.push(this.unitFormS)
      // 			if (this.unitForm.name !== '') {
      // 				data.push(this.unitForm)
      // 			}
      // 			console.log(data)
      // 			this.$store.commit('changeunitList', data)
      // 			this.$EventBus.$emit('changeActive', 'priceManag')
      // 		})
      // 	})

      // });
    },

    // 保存基本信息
    saveBasic() {
      this.$refs.basicForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.goodsInfo.name = this.basicForm.name;
        this.goodsInfo.zjm = this.basicForm.zjm;
        this.goodsInfo.cid = this.basicForm.cid;
        this.goodsInfo.spec = this.basicForm.spec;
        this.goodsInfo.code = this.basicForm.code;
        this.goodsInfo.status = this.basicForm.status;
        this.goodsInfo.place = this.basicForm.place;
		this.goodsInfo.brand_id = this.basicForm.brand_id
        this.$refs.unitFormS.validate(async (valid2) => {
          if (!valid2) {
            return;
          }
          this.goodsInfo.unit1_id = this.unitFormS.bid;
          this.goodsInfo.unit1_code = this.unitFormS.barCode;
          this.$refs.unitForm.validate(async (valid3) => {
            if (!valid2) {
              return;
            }
            this.goodsInfo.unit2_id = this.unitForm.bid;
            this.goodsInfo.unit2_code = this.unitForm.barCode;
            this.goodsInfo.unit2_unit1 = parseInt(this.unitForm.conversion);
            // this.$store.commit("setBasic", this.goodsInfo);
            this.priceManagData = [];
            this.priceManagData.push(this.unitFormS);
            if (this.unitForm.name !== "") {
              this.priceManagData.push(this.unitForm);
            }
            console.log(this.goodsInfo);
            this.flag += 1;
            this.componentsName = this.activeIndex1 = "priceManag";
          });
        });
      });
    },

    savePrice() {
      this.goodsInfo.price = [];
      this.priceManagData.forEach((itm, idx) => {
        this.goodsInfo.price[idx] = {
          unit_id: itm.bid,
          price: parseInt(itm.wholesalePrice),
          price1: parseInt(itm.retailPrice),
          price2: parseInt(itm.minimumPrice),
          price3: parseInt(itm.purchasePrice),
        };
      });
      this.flag += 1;
      this.componentsName = this.activeIndex1 = "imgDetails";
    },

    submitAll() {
      if (!this.cover || !this.goodsDetail || this.goodsDetail==='<p><br></p>' || !this.imgList[0]) {
        this.$message.info("请填写完所有必填项");
        return;
      } else {
        this.goodsInfo.detail = {
          photo: this.cover,
          photos: this.imgList.map(item=>item).join(','),
          description: this.goodsDetail,
        };
      }
      if (this.flag === 2) {
        // 必填信息都填了，上传goodsInfo
        console.log(this.goodsInfo, "商品信息");
        this.$http.post("admin/goods/create", this.goodsInfo).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "商品添加成功",
              type: "success",
            });  
            // 关闭创建商品的窗口
            this.$EventBus.$emit('close','addGoods')
          }
        });
      } else {
        this.$message.info("请跳转至其他页填写所有必填项并点击下一步保存");
        return;
      }
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
.btns {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
}
.basicMsg {
  .basic-form {
    display: block;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .basic-form::after {
    width: 470px;
    content: "";
  }

  .el-select {
    width: 350px !important;
  }

  .el-form-item {
    width: fit-content;
  }

  .el-input {
    width: 350px !important;
  }

  .cards {
    display: flex;
    padding: 50px;
    width: 100%;
    box-sizing: border-box;
  }

  .el-card {
    width: 550px;
    margin-right: 50px;
  }
}

.priceManag {
  .item {
    width: 100px;
  }
  .txt {
    line-height: 24px;
    padding: 0 9px;
    box-sizing: border-box;
  }

  .el-input {
    border: none;
  }
  input.el-input__inner {
    border: none !important;
  }
}

.imgDetails {
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
</style>
